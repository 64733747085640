import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    IncomeEntity,
    PropertyEntity,
    RentCardProps,
    Story,
    UserEntity,
} from '@omedom/data';
import {
    AnalyticsService,
    IncomeService,
    PropertyService,
    UserService,
} from '@omedom/services';
import { OmedomProperty, OmedomStory, OmedomTreasury } from '@omedom/utils';
import { Subscription } from 'rxjs';
import { } from '@omedom/utils';

import { OmedomDateType } from '../../../component/date';

@Component({
    templateUrl: './income-rent.page.html',
    styleUrls: ['./income-rent.page.scss'],
})
export class IncomeRentPage implements OnInit, OnDestroy {
    user: UserEntity;

    storiesByDay: { date: Date; rentCardsProps: RentCardProps[] }[] = [];

    currentDate = new Date().toUTC();

    isLoaded: boolean;

    omedomDateType = OmedomDateType;

    private incomes: IncomeEntity[];

    private properties: PropertyEntity[];

    /**
     * @description List of subscriptions to unsub after destroy component
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {Subscription[]}
     * @memberof IncomeRentComponent
     */
    private subscriptions: Subscription[] = [];

    constructor(
        private incomeService: IncomeService,
        private propertyService: PropertyService,
        private userService: UserService,
        private analyticsService: AnalyticsService
    ) { }

    ngOnInit(): void {
        const user$ = this.userService.user$.subscribe((user) => {
            this.user = user;
            this.loadData().then();
        });

        this.subscriptions.push(user$);
    }

    ionViewDidEnter(): void {
        this.analyticsService.setCurrentScreen('Income rent page');
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    private async loadData(): Promise<void> {
        await Promise.all([
            this.incomeService.getUserIncomes(this.user.uid),
            this.propertyService.getUserPropertiesAndShared(this.user.uid),
        ]).then(([incomes, properties]) => {
            this.incomes = incomes;
            //only rental properties
            this.properties = OmedomProperty.excludeResidentialProperties(properties);
            this.updateData();
        });
    }

    private updateData(): void {
        // only rentIncomes in the current month
        const partialTreasury = OmedomTreasury.getPartialRents(
            this.incomes,
            new Date(this.currentDate),
            this.properties
        );

        const treasuries = OmedomTreasury.transformStreamerModelToTreasury(partialTreasury);
        // sort by day
        const incomesByDay = OmedomTreasury.sortTreasuryByDay(treasuries);

        this.storiesByDay = incomesByDay.map(
            ({ day, treasury }): { date: Date; rentCardsProps: RentCardProps[] } => {
                const rentCardsProps = treasury.map((t): RentCardProps => {
                    const property = this.properties.find(
                        (property) => property.uid === t.propertyUID
                    );
                    const story = OmedomStory.transformTreasuryToStory(t, false, property);
                    return this.getRentCardProps(story);
                });
                return { date: day, rentCardsProps };
            }
        );

        this.isLoaded = true;
    }

    dateChange(date: Date) {
        this.currentDate = new Date(date);
        this.updateData();
    }

    public getRentCardProps(story: Story): RentCardProps {
        const storysIncome = this.incomes.find((income) => income.uid === story.uid);
        const storysProperty = this.properties.find(
            (property) => property.uid === storysIncome.propertyUID
        );
        const rentCardProps = {
            story,
            user: this.user,
            income: storysIncome,
            property: storysProperty,
        };

        return rentCardProps;
    }
}
