// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video {
  width: 60%;
  aspect-ratio: 560/315;
  padding: 20px;
  border-radius: 10px;
}

.video-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;
  width: 50vw;
  margin: auto;
  left: 0;
  right: 0;
  border-radius: 20px;
  padding: 20px;
  background-color: white;
  box-shadow: 0 -10px 15px 0 rgba(4, 21, 28, 0.04);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImVzc2VudGlhbC12aWRlby5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLFVBQUE7RUFDQSxxQkFBQTtFQUNBLGFBQUE7RUFDQSxtQkFBQTtBQUNKOztBQUVBO0VBQ0ksa0JBQUE7RUFDQSxhQUFBO0VBQ0Esc0JBQUE7RUFDQSx1QkFBQTtFQUNBLFlBQUE7RUFDQSxXQUFBO0VBQ0EsWUFBQTtFQUNBLE9BQUE7RUFDQSxRQUFBO0VBQ0EsbUJBQUE7RUFDQSxhQUFBO0VBQ0EsdUJBQUE7RUFDQSxnREFBQTtBQUNKIiwiZmlsZSI6ImVzc2VudGlhbC12aWRlby5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi52aWRlbyB7XG4gICAgd2lkdGg6IDYwJTtcbiAgICBhc3BlY3QtcmF0aW86IDU2MCAvIDMxNTtcbiAgICBwYWRkaW5nOiAyMHB4O1xuICAgIGJvcmRlci1yYWRpdXM6IDEwcHg7XG59XG5cbi52aWRlby1jb250YWluZXIge1xuICAgIHBvc2l0aW9uOiBhYnNvbHV0ZTtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gICAgei1pbmRleDogMTAwO1xuICAgIHdpZHRoOiA1MHZ3O1xuICAgIG1hcmdpbjogYXV0bztcbiAgICBsZWZ0OiAwO1xuICAgIHJpZ2h0OiAwO1xuICAgIGJvcmRlci1yYWRpdXM6IDIwcHg7XG4gICAgcGFkZGluZzogMjBweDtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB3aGl0ZTtcbiAgICBib3gtc2hhZG93OiAwIC0xMHB4IDE1cHggMCByZ2JhKCRjb2xvcjogIzA0MTUxYywgJGFscGhhOiAwLjA0KTtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/components/essential-video/essential-video.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,qBAAA;EACA,aAAA;EACA,mBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,OAAA;EACA,QAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,gDAAA;AACJ;AACA,4mCAA4mC","sourcesContent":[".video {\n    width: 60%;\n    aspect-ratio: 560 / 315;\n    padding: 20px;\n    border-radius: 10px;\n}\n\n.video-container {\n    position: absolute;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    z-index: 100;\n    width: 50vw;\n    margin: auto;\n    left: 0;\n    right: 0;\n    border-radius: 20px;\n    padding: 20px;\n    background-color: white;\n    box-shadow: 0 -10px 15px 0 rgba($color: #04151c, $alpha: 0.04);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
