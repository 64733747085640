import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController, ToastController } from '@ionic/angular';
import {
    AllIncomeCategories,
    IncomeCategoryInfo,
    IncomeCategoryProperty,
    IncomeEntity,
    IncomePeriodicity,
    IncomeUpdateType,
    SelectOption,
} from '@omedom/data';
import { IncomeService, PropertyService, SocietyService, UserService } from '@omedom/services';
import { OmedomNumberPipe } from '@omedom/ui';

import { TreasuryEdit } from '../../../utils/class';

@Component({
    selector: 'app-income-edit',
    templateUrl: './income-edit.page.html',
    styleUrls: ['./income-edit.page.scss'],
})
export class IncomeEditPage extends TreasuryEdit<IncomeEntity, AllIncomeCategories> {
    incomeUpdateType = IncomeUpdateType;

    incomePeriodicity = IncomePeriodicity;

    constructor(
        userService: UserService,
        propertyService: PropertyService,
        societyService: SocietyService,
        numberPipe: OmedomNumberPipe,
        datePipe: DatePipe,
        activatedRoute: ActivatedRoute,
        private readonly navController: NavController,
        private readonly toastController: ToastController,
        private readonly incomeService: IncomeService,
    ) {
        super(userService, propertyService, societyService, numberPipe, datePipe, activatedRoute);
    }

    async updateEntity(treasury: Partial<IncomeEntity>): Promise<void> {
        if (new Date(this.startDate) > new Date(this.endDate)) {
            const toast = await this.toastController.create({
                position: 'top',
                color: 'danger',
                duration: 5000,
                message:
                    'Veuillez renseigner une date de début de contrat antérieure à la date de fin',
            });

            await toast.present();
            return;
        }

        // TODO: Check and fix that in treasury
        if (!treasury.futurPayment) {
            delete treasury.futurPayment;
        }

        try {
            await this.incomeService.update(treasury);
            const toast = await this.toastController.create({
                position: 'top',
                color: 'primary',
                duration: 4000,
                message: 'Vous avez mise à jour un revenu.',
            });

            await toast.present();

            if (window.history.state?.navigationId > 1) {
                await this.navController.back();
            } else {
                await this.navController.pop();
            }
        } catch (error) {
            console.error(error);
            const toast = await this.toastController.create({
                position: 'top',
                color: 'danger',
                duration: 4000,
                message: "Une erreur s'est produite, veuillez réessayer plus tard.",
            });
            await toast.present();
        }
    }

    getTreasury(treasuryUid: string): Promise<IncomeEntity> {
        const income = this.incomeService.get(treasuryUid);
        console.log('income', income);
        return income;
    }

    buildPeriodicityOptions(treasury: IncomeEntity): SelectOption[] {
        return treasury.periodicity === IncomePeriodicity.punctual
            ? [
                  {
                      id: IncomePeriodicity.punctual,
                      label: 'Ponctuelle',
                  } as SelectOption,
              ]
            : [
                  {
                      id: IncomePeriodicity.monthly,
                      label: 'Mensuelle',
                  } as SelectOption,
                  {
                      id: IncomePeriodicity.bimonthly,
                      label: 'Bimestrielle',
                  } as SelectOption,
                  {
                      id: IncomePeriodicity.quarterly,
                      label: 'Trimestrielle',
                  } as SelectOption,
                  {
                      id: IncomePeriodicity.halfYearly,
                      label: 'Semestrielle',
                  } as SelectOption,
                  {
                      id: IncomePeriodicity.yearly,
                      label: 'Annuelle',
                  } as SelectOption,
              ];
    }

    getCategoryInfo(category: string): IncomeCategoryInfo {
        return new IncomeCategoryInfo(category as IncomeCategoryProperty);
    }
}
