import { Component, NgZone } from '@angular/core';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import { Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { UserEntity } from '@omedom/data';
import {
    UserService,
    AnalyticsService,
    NotificationService,
    ConfigurationService,
    SubscriptionService,
} from '@omedom/services';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { NewCgvValidationModalComponent } from './common/newCgv-validation-modal/newCgv-validation-modal.component';
import { UpdateComponent } from './common/update/update.component';
import { LevelService } from '@omedom/services';
import { LevelUpComponent } from '@omedom/ui';
import 'capacitor-plugin-app-tracking-transparency';
import {
    AppTrackingStatusResponse,
    AppTrackingTransparency,
} from 'capacitor-plugin-app-tracking-transparency';
import { take } from 'rxjs/operators';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { OmedomDeepLink } from '@omedom/utils';
// import { AdjustInit, AdjustSDK } from '@omedom/adjust-capacitor-plugin';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    private user$: Observable<UserEntity>;
    private user: UserEntity;
    // private popups: Popup[] | any[] = [];

    private cgvValidatedVersion: Date;
    public cgvCurrentDate = new Date(environment.cgvCurrentDate);
    private appURL = environment.production ? 'https://app.omedom.com' : 'https://dev.omedom.com';
    private presentCgv = false;

    private isAnalyticsUserSet = false;

    constructor(
        private zone: NgZone,
        private router: Router,
        private userService: UserService,
        private platform: Platform,
        private modalController: ModalController,
        private levelService: LevelService,
        private analyticsService: AnalyticsService,
        private notificationService: NotificationService,
        private configurationService: ConfigurationService,
        private subscriptionService: SubscriptionService,
    ) {
        this.initializeApp();

        this.platform.ready().then(() => {
            if (platform.is('ios') && environment.production) {
                this.getStatus();
            }

            if (!platform.is('mobile')) {
                // GoogleAuth.initialize({
                //     clientId:initializeApp
                //         '873146377704-q6ahc025jaf8ba475n2puldbpgjjp2jk.apps.googleusercontent.com',
                // });
                // GoogleAuth.initialize()
            }

            LevelService.levelUpComponent = LevelUpComponent;
            this.levelService.observeSuccess();
            this.user$ = this.userService.user$;
            this.user$.subscribe((user) => {
                if (user) {
                    this.user = user;

                    this.cgvValidatedVersion =
                        new Date(this.user.cgvValidatedVersion ?? '') || null;
                    if (
                        (!this.user.cgvValidatedVersion ||
                            this.cgvValidatedVersion < this.cgvCurrentDate) &&
                        this.presentCgv === false
                    ) {
                        this.validNewCgv();
                    }
                    this.subscriptionService.updateLocalSubscription();

                    this.setAnalyticsUser();
                }
            });

            this.configurationService.watchConfiguration().subscribe((configuration) => {
                this.configurationService.configuration$.next(configuration);
            });

            this.user$.pipe(take(1)).subscribe((user) => {
                if (user) {
                    this.notificationService.initPush();
                }
            });

            if (platform.is('mobile') && environment.production) {
                this.checkForUpdate();
            }
        });
    }

    public async checkForUpdate() {
        const result = await AppUpdate.getAppUpdateInfo();
        if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
            return;
        }

        const modal = await this.modalController.create({
            component: UpdateComponent,
            backdropDismiss: false,
            initialBreakpoint: 1,
            showBackdrop: true,
        });

        await modal.present();

        const { data } = await modal.onWillDismiss(); // wait for modal to dismiss
        if (data && data.update) {
            await AppUpdate.openAppStore();
        }
    }

    public async validNewCgv() {
        this.presentCgv = true;
        const modal = await this.modalController.create({
            component: NewCgvValidationModalComponent,
            breakpoints: [0, 1],
            backdropDismiss: false,
            initialBreakpoint: 1,
            showBackdrop: true,
        });

        await modal.present();
    }

    /**
     * @description Ask for tracking authorization
     * @author Jérémie Lopez
     * @return {*}  {Promise<AppTrackingStatusResponse>}
     * @memberof AppComponent
     */
    public async getStatus(): Promise<AppTrackingStatusResponse> {
        const response = await AppTrackingTransparency.getStatus();

        if (response?.status === 'notDetermined') {
            const request = await AppTrackingTransparency.requestPermission();

            if (request?.status === 'authorized') {
                console.info('Tracking authorized');
            }
        }

        return response;
    }

    /**
     * @description Set user un Analytics (one time)
     * @author ANDRE Felix
     * @private
     * @memberof AppComponent
     */
    private setAnalyticsUser() {
        if (!this.isAnalyticsUserSet) {
            this.isAnalyticsUserSet = true;
            this.analyticsService.setUserId(this.user.uid);
        }
    }

    /**
     * @description Initialize the app and listen to appUrlOpen event to handle deep links routing in the app
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 07/10/2024
     * @memberof AppComponent
     */
    private async initializeApp() {
        // await this.initializeAdjustSDK();

        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            // console.log('#APPURLOPEN Event received');
            this.zone.run(() => {
                // console.log('#NGZONE App opened with URL: ' + event.url);
                if (!event.url) {
                    return;
                }
                // Example url: https://app.omedom.com/route/something
                // route = /route/something
                // console.log('#APPURL: ' + this.appURL);
                const route = OmedomDeepLink.retrieveRoute(event.url, this.appURL);
                if (route) {
                    // console.log('#SLUG Navigating to: ' + route);
                    this.router.navigateByUrl(route);
                }
                // If no match, do nothing - let regular routing
                // logic take over
            });
        });
    }
}
