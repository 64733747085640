import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-treasury-card',
    templateUrl: './treasury-card.component.html',
    styleUrls: ['./treasury-card.component.scss'],
})
export class TreasuryCardComponent {
    @Input() treasury: any;

    @Output() treasuryDeleted = new EventEmitter();

    @Input() showProperty: boolean = false;

    @Input() canDelete: boolean = false;

    @Input() backgroundColor: string;

    removeTreasury() {
        this.treasuryDeleted.emit();
    }
}
