import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController, ToastController } from '@ionic/angular';
import {
    AllChargeCategories,
    ChargeCategoryInfo,
    ChargeCategoryProperty,
    ChargeEntity,
    ChargePeriodicity,
    ChargeUpdateType,
    SelectOption,
} from '@omedom/data';
import { ChargeService, PropertyService, SocietyService, UserService } from '@omedom/services';
import { OmedomNumberPipe } from '@omedom/ui';

import { TreasuryEdit } from '../../../utils/class';

@Component({
    selector: 'app-charge-edit',
    templateUrl: './charge-edit.page.html',
    styleUrls: ['./charge-edit.page.scss'],
})
export class ChargeEditPage extends TreasuryEdit<ChargeEntity, AllChargeCategories> {
    chargeUpdateType = ChargeUpdateType;

    chargePeriodicity = ChargePeriodicity;

    constructor(
        userService: UserService,
        propertyService: PropertyService,
        societyService: SocietyService,
        numberPipe: OmedomNumberPipe,
        datePipe: DatePipe,
        activatedRoute: ActivatedRoute,
        private readonly navController: NavController,
        private readonly toastController: ToastController,
        private readonly chargeService: ChargeService,
    ) {
        super(userService, propertyService, societyService, numberPipe, datePipe, activatedRoute);
    }

    async updateEntity(treasury: Partial<ChargeEntity>): Promise<void> {
        if (new Date(this.startDate) > new Date(this.endDate)) {
            const toast = await this.toastController.create({
                position: 'top',
                color: 'danger',
                duration: 5000,
                message:
                    'Veuillez renseigner une date de début de contrat antérieure à la date de fin',
            });

            await toast.present();
            return;
        }

        // TODO: Check and fix that in treasury
        if (!treasury.futurPayment) {
            delete treasury.futurPayment;
        }

        try {
            await this.chargeService.update(treasury);
            console.log('treasury updated');
            const toast = await this.toastController.create({
                position: 'top',
                color: 'primary',
                duration: 4000,
                message: 'Vous avez mise à jour une charge.',
            });

            await toast.present();

            if (window.history.state?.navigationId > 1) {
                await this.navController.back();
            } else {
                await this.navController.pop();
            }
        } catch (error) {
            console.error(error);
            const toast = await this.toastController.create({
                position: 'top',
                color: 'danger',
                duration: 4000,
                message: "Une erreur s'est produite, veuillez réessayer plus tard.",
            });
            await toast.present();
        }
    }

    getTreasury(treasuryUid: string): Promise<ChargeEntity> {
        const charge = this.chargeService.get(treasuryUid);
        console.log('charge', charge);
        return charge;
    }

    buildPeriodicityOptions(treasury: ChargeEntity): SelectOption[] {
        return treasury.periodicity === ChargePeriodicity.punctual
            ? [
                  {
                      id: ChargePeriodicity.punctual,
                      label: 'Ponctuelle',
                  } as SelectOption,
              ]
            : [
                  {
                      id: ChargePeriodicity.monthly,
                      label: 'Mensuelle',
                  } as SelectOption,
                  {
                      id: ChargePeriodicity.bimonthly,
                      label: 'Bimestrielle',
                  } as SelectOption,
                  {
                      id: ChargePeriodicity.quarterly,
                      label: 'Trimestrielle',
                  } as SelectOption,
                  {
                      id: ChargePeriodicity.halfYearly,
                      label: 'Semestrielle',
                  } as SelectOption,
                  {
                      id: ChargePeriodicity.yearly,
                      label: 'Annuelle',
                  } as SelectOption,
              ];
    }

    getCategoryInfo(category: string): ChargeCategoryInfo {
        return new ChargeCategoryInfo(category as ChargeCategoryProperty);
    }
}
