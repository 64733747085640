import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'app-action',
    templateUrl: './action.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ActionComponent {
    @Input() text: string;

    @Input() actionIcon: string;

    @Input() simpleIcon: boolean = false;

    @Output() actionClicked = new EventEmitter<void>();
}
