import { Component } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

@Component({
    selector: 'app-update',
    templateUrl: './update.component.html',
    styleUrls: ['./update.component.scss'],
})
export class UpdateComponent {
    isIos = this.platform.is('ios');
    isAndroid = this.platform.is('android');

    constructor(private platform: Platform, private modalController: ModalController) {}

    public openStore(): void {
        this.modalController.dismiss({
            update: true,
        });
    }
}
