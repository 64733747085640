import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { EmailVerificationGuard } from './guards/email-verification.guard';
import { LicenceGuard } from './guards/licence.guard';
import { NoAuthGuard } from './guards/no-auth.guard';
import { PropertyOwnerGuard } from './guards/property-owner.guard';
import { ProviderGuard } from './guards/provider.guard';
import { WelcomeGuard } from './guards/welcome.guard';

const routes: Routes = [
    {
        path: '',
        canActivate: [
            AuthGuard,
            EmailVerificationGuard,
            WelcomeGuard,
            LicenceGuard,
            ProviderGuard,
            // GoalGuard,
            PropertyOwnerGuard,
        ],
        loadChildren: () => import('./tabs/tabs.module').then((m) => m.TabsPageModule),
    },
    {
        path: 'user',
        canActivate: [NoAuthGuard],
        loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
    },
    {
        path: 'tunnel',
        canActivate: [],
        loadChildren: () => import('./tunnel/tunnel.module').then((m) => m.TunnelModule),
    },
    {
        path: '**',
        redirectTo: '',
    },

    // {
    //     path: 'receipt-form',
    //     loadChildren: () => import('./receipt/receipt.module').then(m => m.ReceiptFormPageModule)
    // }
];
@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
