// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list {
  width: 100%;
  display: grid;
  grid-gap: 20px;
}
.list.list-view {
  grid-template-columns: 1fr;
}
.list.card-view {
  grid-template-columns: 1fr 1fr;
}
.list.card-selection {
  grid-template-columns: 1fr 1fr;
}

.title {
  font-weight: bold;
  font-size: 20px;
  margin: 20px 0;
}

.empty-asset {
  font-weight: bold;
  margin-left: 20px;
  grid-area: span 1/span 2;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNoYXJlLXRvLXByby5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLFdBQUE7RUFDQSxhQUFBO0VBQ0EsY0FBQTtBQUNKO0FBQUk7RUFDSSwwQkFBQTtBQUVSO0FBQUk7RUFDSSw4QkFBQTtBQUVSO0FBQUk7RUFDSSw4QkFBQTtBQUVSOztBQUVBO0VBQ0ksaUJBQUE7RUFDQSxlQUFBO0VBQ0EsY0FBQTtBQUNKOztBQUVBO0VBQ0ksaUJBQUE7RUFDQSxpQkFBQTtFQUNBLHdCQUFBO0FBQ0oiLCJmaWxlIjoic2hhcmUtdG8tcHJvLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmxpc3Qge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGRpc3BsYXk6IGdyaWQ7XG4gICAgZ3JpZC1nYXA6IDIwcHg7XG4gICAgJi5saXN0LXZpZXcge1xuICAgICAgICBncmlkLXRlbXBsYXRlLWNvbHVtbnM6IDFmcjtcbiAgICB9XG4gICAgJi5jYXJkLXZpZXcge1xuICAgICAgICBncmlkLXRlbXBsYXRlLWNvbHVtbnM6IDFmciAxZnI7XG4gICAgfVxuICAgICYuY2FyZC1zZWxlY3Rpb24ge1xuICAgICAgICBncmlkLXRlbXBsYXRlLWNvbHVtbnM6IDFmciAxZnI7XG4gICAgfVxufVxuXG4udGl0bGUge1xuICAgIGZvbnQtd2VpZ2h0OiBib2xkO1xuICAgIGZvbnQtc2l6ZTogMjBweDtcbiAgICBtYXJnaW46IDIwcHggMDtcbn1cblxuLmVtcHR5LWFzc2V0IHtcbiAgICBmb250LXdlaWdodDogYm9sZDtcbiAgICBtYXJnaW4tbGVmdDogMjBweDtcbiAgICBncmlkLWFyZWE6IHNwYW4gMSAvIHNwYW4gMjtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/containers/share-to-pro/share-to-pro.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,cAAA;AACJ;AAAI;EACI,0BAAA;AAER;AAAI;EACI,8BAAA;AAER;AAAI;EACI,8BAAA;AAER;;AAEA;EACI,iBAAA;EACA,eAAA;EACA,cAAA;AACJ;;AAEA;EACI,iBAAA;EACA,iBAAA;EACA,wBAAA;AACJ;AACA,4kCAA4kC","sourcesContent":[".list {\n    width: 100%;\n    display: grid;\n    grid-gap: 20px;\n    &.list-view {\n        grid-template-columns: 1fr;\n    }\n    &.card-view {\n        grid-template-columns: 1fr 1fr;\n    }\n    &.card-selection {\n        grid-template-columns: 1fr 1fr;\n    }\n}\n\n.title {\n    font-weight: bold;\n    font-size: 20px;\n    margin: 20px 0;\n}\n\n.empty-asset {\n    font-weight: bold;\n    margin-left: 20px;\n    grid-area: span 1 / span 2;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
