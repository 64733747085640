import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import { BankAccountEntity, BankItemEntity, TutorialVideoName, UserEntity } from '@omedom/data';
import {
    BankAccountService,
    BankItemService,
    TutorialVideoService,
    UserService,
} from '@omedom/services';
import {
    BankDeleteConfirmContainer,
    BankingAmountWidgetLayout,
    BankSyncSuccessComponent,
    elementAnimation,
    listAnimation,
    MaintenanceEnum,
    OmedomIsAuthorisedPipe,
    OmedomIsMaintenancePipe,
} from '@omedom/ui';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Component({
    selector: 'omedom-bank-home',
    templateUrl: './bank-home.page.html',
    styleUrls: ['./bank-home.page.scss'],
    animations: [elementAnimation, listAnimation],
})
export class BankHomePage implements OnInit, OnDestroy {
    /**
     * @description User data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/04/2024
     * @type {Observable<UserEntity>}
     * @memberof BankHomePage
     */
    public user$: Observable<UserEntity>;

    /**
     * @description Bank items of the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/04/2024
     * @type {BankItemEntity[]}
     * @memberof BankHomePage
     */
    public items: BankItemEntity[] = [];

    /**
     * @description Bank accounts of the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @type {BankAccountEntity[]}
     * @memberof BankHomePage
     */
    public accounts: BankAccountEntity[] = [];

    /**
     * @description State of the component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/04/2024
     * @memberof BankHomePage
     */
    public state$ = new BehaviorSubject<string>('pending');

    /**
     * @description State of the component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 19/04/2024
     * @memberof BankHomePage
     */
    public syncState$ = new BehaviorSubject<string>('ok');

    /**
     * @description Message to display to the user in the template
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 19/04/2024
     * @memberof BankHomePage
     */
    public syncMessage$ = new BehaviorSubject<string>('Connecter une banque');

    /**
     * @description Closest expiration date of the user bank items to display in the template
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/06/2024
     * @type {Date}
     * @memberof BankHomePage
     */
    public closestExpirationDate?: Date;

    /**
     * @description Delete subscriptions after destroy
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/04/2024
     * @private
     * @type {Subscription[]}
     * @memberof BankHomePage
     */
    private subscriptions: Subscription[] = [];

    protected bankingAmountWidgetLayout = BankingAmountWidgetLayout.DASHBOARD;

    /**
     * @description Used to give access to the bank sync feature when the user have the appropriate subscription plan
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 17/06/2024
     * @private
     * @memberof BankHomePage
     */
    public canSyncBank = false;

    public isMaintenance = true;

    bankTutorialVideoLink: string;

    protected bankInfoPopoverContent = [
        'Votre sécurité est notre priorité, nous avons mis en place la déconnexion automatique effective après 30 minutes d’inactivité.',
        "De plus, nous faisons appel à notre partenaire Bridge (France), agréé par l'ACPR - Banque de France (Autorité de Contrôle Prudentiel et de résolution).",
        'Enfin, veuillez noter que le partage à un tiers ne permettra jamais l’accès à vos informations bancaires.',
    ];
    constructor(
        private userService: UserService,
        private bankItemService: BankItemService,
        private bankAccountService: BankAccountService,
        private toastController: ToastController,
        private platform: Platform,
        private activatedRoute: ActivatedRoute,
        private modalController: ModalController,
        private navController: NavController,
        private omedomIsAuthorisedPipe: OmedomIsAuthorisedPipe,
        private omedomIsMaintenancePipe: OmedomIsMaintenancePipe,
        private tutorialVideoService: TutorialVideoService,
    ) {}

    async ngOnInit() {
        this.omedomIsAuthorisedPipe.transform('accessManageBank').subscribe((isAuthorised) => {
            this.canSyncBank = isAuthorised;
        });

        this.omedomIsMaintenancePipe.transform('bank').subscribe((maintenance) => {
            this.isMaintenance = MaintenanceEnum.MAINTENANCE === maintenance;
        });

        // Get user data
        this.user$ = this.userService.user$;

        // Get bank items
        this.getItems();

        // Get bank accounts
        this.getAccounts();

        // Get query params
        this.activatedRoute.queryParams.subscribe((params) => {
            if (params.syncSuccess) {
                this.displaySyncSuccessModal();
            }
        });

        await this.getTutorialVideoLink();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    async getTutorialVideoLink() {
        this.bankTutorialVideoLink = await this.tutorialVideoService.getVideoLinkByName(
            TutorialVideoName.desktopFirst,
        );
    }

    /**
     * @description Display sync success modal to the user after a successful bank sync session connection
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @private
     * @returns {Promise<void>}
     * @memberof BankHomePage
     */
    private async displaySyncSuccessModal(): Promise<void> {
        const modal = await this.modalController.create({
            component: BankSyncSuccessComponent,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
        });

        await modal.present();
    }

    /**
     * @description Init the getter of bank items
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/04/2024
     * @private
     * @memberof BankHomePage
     */
    private getItems(): void {
        this.subscriptions.push(
            this.user$
                .pipe(
                    switchMap((user) => {
                        if (user) {
                            return this.bankItemService._getUserBankItems(user.uid).pipe(
                                tap(() => {
                                    this.state$.next('ok');
                                }),
                            );
                        } else {
                            return of([]);
                        }
                    }),
                )
                .subscribe((items) => {
                    this.items = items;
                    this.setClosestExpirationDate();
                }),
        );
    }

    /**
     * @description Init the getter of bank accounts linked to the user bank items
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @private
     * @memberof BankHomePage
     */
    private getAccounts(): void {
        this.subscriptions.push(
            this.user$
                .pipe(
                    switchMap((user) => {
                        if (user) {
                            return this.bankAccountService._getUserBankAccounts(user.uid).pipe(
                                tap(() => {
                                    this.state$.next('ok');
                                }),
                            );
                        } else {
                            return of([]);
                        }
                    }),
                )
                .subscribe((accounts) => {
                    this.accounts = accounts;
                }),
        );
    }

    /**
     * @description Generate bank sync session and redirect the user to the bank website to connect his account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 19/04/2024
     * @param {UserEntity} user
     * @returns {Promise<void>}
     * @memberof BankHomePage
     */
    public async generateBankSyncSession(user: UserEntity): Promise<void> {
        if (!this.canSyncBank || this.isMaintenance) {
            return;
        }

        // Check if bridge user exists
        await this.patchBridgeUser(user);

        // Init state
        this.syncState$.next('pending');

        // Init message
        this.syncMessage$.next('Génération de la session...');

        try {
            // Generate bank sync session
            const url = await this.bankItemService.generateBankSyncSession();

            // Redirect user to url
            this.syncMessage$.next('Redirection vers la session...');

            if (this.platform.is('capacitor')) {
                await Browser.open({ url });
            } else {
                window.location.href = url;
            }
        } catch (error) {
            const toast = await this.toastController.create({
                message:
                    'Une erreur est survenue lors de la génération de la session de synchronisation. Veuillez réessayer.',
                duration: 3000,
                color: 'danger',
            });

            toast.present();
        }

        // Reset state
        this.syncState$.next('ok');

        // Reset message
        this.syncMessage$.next('Connecter une banque');
    }

    /**
     * @description Patch bridge user if it doesn't exist
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 19/04/2024
     * @private
     * @param {UserEntity} user
     * @returns {Promise<void>}
     * @memberof BankEmptyComponent
     */
    private async patchBridgeUser(user: UserEntity): Promise<void> {
        // Check if bridge user exists
        if (user.bridgeUUID) {
            return;
        }

        // Init state
        this.syncState$.next('pending');

        // Init message
        this.syncMessage$.next("Création de l'utilisateur Bridge...");

        try {
            // Patch bridge user
            await this.bankItemService.patchBridgeUser();
        } catch (error) {
            const toast = await this.toastController.create({
                message:
                    "Une erreur est survenue lors de la création de l'utilisateur Bridge. Veuillez réessayer.",
                duration: 3000,
                color: 'danger',
            });

            toast.present();
        }

        // Reset state
        this.syncState$.next('ok');

        // Reset message
        this.syncMessage$.next('Connecter une banque');
    }

    /**
     * @description Filter bank accounts by item ID to display them in the template by item group
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @param {BankItemEntity} item
     * @returns {BankAccountEntity[]}
     * @memberof BankHomePage
     */
    public getItemAccounts(item: BankItemEntity): BankAccountEntity[] {
        return this.accounts.filter((account) => account.itemID === item.bridgeID);
    }

    /**
     * @description Delete bank item of the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @param {BankItemEntity} item
     * @returns {Promise<void>}
     * @memberof BankHomePage
     */
    public async deleteItem(item: BankItemEntity): Promise<void> {
        // Display confirmation modal
        const modal = await this.modalController.create({
            component: BankDeleteConfirmContainer,
            componentProps: {
                item,
                accounts: this.getItemAccounts(item),
            },
            initialBreakpoint: 1,
            breakpoints: [0, 1],
        });

        await modal.present();
    }

    public async editItem(item: BankItemEntity): Promise<void> {
        await this.navController.navigateForward(['/tabs/bank/form'], {
            queryParams: {
                select: item.uid,
            },
        });
    }

    public goToSynthesis(item: BankItemEntity) {
        this.navController.navigateForward(['/tabs/bank/', item.uid], {
            queryParams: {
                tab: 'transactions',
            },
        });
    }

    /**
     * @description Set the closest expiration date of the user bank items
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/06/2024
     * @readonly
     * @type {void}
     * @memberof BankHomePage
     */
    public setClosestExpirationDate(): void {
        const expirationDates = this.items
            .filter((item) => item.expirationDate)
            .map((item) => item.expirationDate);

        const now = new Date();

        this.closestExpirationDate = expirationDates.length
            ? expirationDates.reduce((acc, date) => {
                  return date < acc && date > now ? date : acc;
              }, expirationDates[0])
            : undefined;
    }

    /**
     * @description Redirect the user to the subscriptions page to change his subscription
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/06/2024
     * @memberof BankHomePage
     */
    public goToSubscription(): void {
        this.navController.navigateForward(['/tabs/menu/subscription'], {
            queryParams: { tab: 'manage' },
        });
    }

    /**
     * @description Nav to youtube to watch video tuto
     * @author ANDRE Felix
     * @returns {*}
     * @memberof BankHomePage
     */
    public goToValoTutoVideo() {
        if (!this.bankTutorialVideoLink) {
            return;
        }
        window.location.href = this.bankTutorialVideoLink;
    }
}
