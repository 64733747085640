import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-suggest-smart',
    templateUrl: './suggest-smart.component.html',
    styleUrls: ['./suggest-smart.component.scss'],
})
export class SuggestSmartComponent {
    @Input() text: string;

    isAppleDevice: boolean;

    constructor(private router: Router) { }

    public goSmart(): void {
        this.router.navigate(
            ['/tabs/menu/subscription'],
            { queryParams: { tab: 'manage' } },
        );
    }
}
