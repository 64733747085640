import { EventEmitter, Injectable, OnDestroy, OnInit, Output, Type } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import {
    CategoryInfo,
    ChargeEntity,
    ChargeListModel,
    ChargePeriodicity,
    ChargeUpdateType,
    IncomeEntity,
    IncomeListModel,
    IncomePeriodicity,
    IncomeUpdateType,
    OmedomDateType,
    PeriodicityInfo,
    PropertyEntity,
    SocietyEntity,
    Story,
    TreasuryListModel,
    UserEntity,
} from '@omedom/data';
import { PropertyService, SocietyService, UserService } from '@omedom/services';
import { OmedomStory, OmedomTreasury } from '@omedom/utils';
import { combineLatest, Subscription } from 'rxjs';

import { ChargeEditTypeComponent, IncomeEditTypeComponent } from '../components';

/* eslint-disable @angular-eslint/contextual-lifecycle */
@Injectable()
export abstract class TreasuryList<
        TEntity extends ChargeEntity | IncomeEntity,
        TCategory,
        TPeriodicity,
    >
    implements OnInit, OnDestroy
{
    treasuryByDay: {
        day: Date;
        treasury: TreasuryListModel<TCategory, TPeriodicity>[];
    }[] = [];

    get emptyTreasury(): boolean {
        return (
            this.treasuryByDay.length === 0 ||
            this.treasuryByDay.every((x) => x.treasury.length === 0)
        );
    }

    now = new Date();

    startDate: Date = new Date().getUTCFirstDayOfMonth();

    endDate: Date = new Date().getUTCLastDayOfMonth();

    omedomDateType = OmedomDateType;

    editable = true;

    notes?: string;

    protected propertyUid?: string;

    protected societyUid?: string;

    private subscription?: Subscription;

    private userSubscription?: Subscription;

    protected properties: PropertyEntity[] = [];

    protected societies: SocietyEntity[] = [];

    private storyModal?: HTMLIonModalElement;

    user?: UserEntity;

    @Output() editClickedEvent = new EventEmitter<Story>();

    protected constructor(
        protected userService: UserService,
        protected propertyService: PropertyService,
        private activatedRoute: ActivatedRoute,
        protected modalController: ModalController,
        private router: Router,
        protected societyService: SocietyService,
    ) {}

    ngOnInit(): void {
        const user$ = this.userService.user$;
        this.userSubscription = user$.subscribe((user) => {
            this.user = user;
            const properties$ = this.propertyService._getUserPropertiesAndSharedAccessible(
                this.user.uid,
            );
            const societies$ = this.societyService._getUserSocietiesAndShared(this.user.uid);
            this.subscription = combineLatest([
                this.activatedRoute.paramMap,
                this.activatedRoute.fragment,
                this.activatedRoute.queryParams,
                properties$,
                societies$,
            ]).subscribe(async ([paramMap, fragment, queryParams, properties, societies]) => {
                const startDateParam = paramMap.get('startDate') ?? queryParams['startDate'];
                const endDateParam = paramMap.get('endDate') ?? queryParams['endDate'];

                const startDate = startDateParam
                    ? new Date(startDateParam)
                    : new Date().getUTCFirstDayOfMonth();
                const endDate = endDateParam
                    ? new Date(endDateParam)
                    : new Date().getUTCLastDayOfMonth();

                const property = paramMap.get('propertyUid');
                const society = paramMap.get('societyUid');
                const editable = fragment === 'view';

                this.startDate = startDate;
                this.endDate = endDate;
                this.propertyUid = property ?? undefined;
                this.societyUid = society ?? undefined;
                this.properties = properties ?? [];
                this.societies = societies ?? [];
                this.editable = !editable;

                this.updateData();
            });
        });
    }

    async ionViewWillEnter(): Promise<void> {
        if (this.user) {
            await this.updateData(); // fix refreshing of list
        }
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
        this.userSubscription?.unsubscribe();
    }

    abstract loadTreasury(userUid: string): Promise<TEntity[]>;

    abstract getCategoryInfo(category: string): CategoryInfo<TCategory>;

    abstract getPeriodicityInfo(periodicity: string): PeriodicityInfo<TPeriodicity>;

    async startDateChange(date: Date): Promise<void> {
        this.startDate = date;
        await this.updateData();
    }

    async endDateChange(date: Date): Promise<void> {
        this.endDate = date;
        await this.updateData();
    }

    protected async showDeleteComponent<T>(
        type: Type<T>,
        // treasuryListModel: TreasuryListModel<TCategory, TPeriodicity>
        treasuryListModel: ChargeListModel | IncomeListModel,
    ): Promise<HTMLIonModalElement> {
        const modal = await this.modalController.create({
            component: type,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            canDismiss: true,
            componentProps: {
                treasuryListModel,
                label: 't',
            },
        });

        await modal.present();

        modal.onDidDismiss().then(() => {
            this.updateData();
            this.modalController.dismiss();
        });
        return modal;
    }

    protected async showEditComponent<T>(type: Type<T>): Promise<HTMLIonModalElement> {
        const modal = await this.modalController.create({
            component: type,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            canDismiss: true,
        });

        await modal.present();

        return modal;
    }

    protected async updateData(): Promise<void> {
        if (!this.user) {
            return;
        }

        const allEntities = await this.loadTreasury(this.user.uid);
        const entities = allEntities.filter((x) => !x.isDeleted);
        const startDate = this.startDate;
        const endDate = this.endDate;
        if (!startDate || !endDate) {
            return;
        }
        const filteredData = OmedomTreasury.filterTreasury<TEntity>(
            entities,
            startDate.getUTCDateWithoutTime(),
            endDate.getUTCDateWithoutTime(),
        );

        const propertiesTreasuryByMonth = this.getMonthPropertyTreasury(
            filteredData,
            startDate,
            endDate,
        );
        const societyTreasuryByMonth = this.getMonthSocietyTreasury(
            filteredData,
            startDate,
            endDate,
        );

        const treasuryByDay = [...propertiesTreasuryByMonth, ...societyTreasuryByMonth].reduce(
            (
                tbt: {
                    [key: string]: TreasuryListModel<TCategory, TPeriodicity>[];
                },
                treasury,
            ) => ({
                ...tbt,
                [treasury.date.toString()]: [...(tbt[treasury.date.toString()] || []), treasury],
            }),
            {},
        );

        const sortedTreasuryByDay = Object.keys(treasuryByDay).sort(
            (a, b) => new Date(a).getTime() - new Date(b).getTime(),
        );

        this.treasuryByDay = sortedTreasuryByDay.map((date) => ({
            day: new Date(date),
            treasury: treasuryByDay[date],
        }));
    }

    private getMonthPropertyTreasury(
        entities: TEntity[],
        startDate: Date,
        endDate: Date,
    ): TreasuryListModel<TCategory, TPeriodicity>[] {
        const treasuryByMonth: TreasuryListModel<TCategory, TPeriodicity>[] = [];

        let currentDate = new Date(startDate);

        let index = 0;

        while (
            currentDate.getUTCFirstDayOfMonth().getTime() <=
                endDate.getUTCFirstDayOfMonth().getTime() &&
            index < 1000
        ) {
            const currentStartDate = currentDate.getUTCFirstDayOfMonth();
            const currentEndDate = currentDate.getUTCLastDayOfMonth();

            const monthEntities = OmedomTreasury.filterTreasury<TEntity>(
                entities,
                currentStartDate,
                currentEndDate,
            );

            const monthTreasury = monthEntities
                .filter((entity) => !!this.properties.find((x) => x.uid === entity.propertyUID))
                .map((entity): TreasuryListModel<TCategory, TPeriodicity> => {
                    const property = this.properties.find((x) => x.uid === entity.propertyUID);

                    return this.transformEntityInTreasury(
                        entity,
                        property,
                        currentStartDate,
                        currentEndDate,
                    );
                });

            treasuryByMonth.push(...monthTreasury);

            index++;
            currentDate = currentDate.addMonths(1);
        }

        return treasuryByMonth;
    }

    private getMonthSocietyTreasury(
        entities: TEntity[],
        startDate: Date,
        endDate: Date,
    ): TreasuryListModel<TCategory, TPeriodicity>[] {
        const treasuryByMonth: TreasuryListModel<TCategory, TPeriodicity>[] = [];

        let currentDate = new Date(startDate);

        let index = 0;

        while (
            currentDate.getUTCFirstDayOfMonth().getTime() <=
                endDate.getUTCFirstDayOfMonth().getTime() &&
            index < 1000
        ) {
            const currentStartDate = currentDate.getUTCFirstDayOfMonth();
            const currentEndDate = currentDate.getUTCLastDayOfMonth();

            const monthEntities = OmedomTreasury.filterTreasury<TEntity>(
                entities,
                currentStartDate,
                currentEndDate,
            );

            const monthTreasury = monthEntities
                .filter((entity) => !!this.societies.find((x) => x.uid === entity.societyUID))
                .map((entity): TreasuryListModel<TCategory, TPeriodicity> => {
                    const society = this.societies.find((x) => x.uid === entity.societyUID);

                    return this.transformEntityInTreasury(
                        entity,
                        society,
                        currentStartDate,
                        currentEndDate,
                    );
                });

            treasuryByMonth.push(...monthTreasury);

            index++;
            currentDate = currentDate.addMonths(1);
        }

        return treasuryByMonth;
    }

    /**
     * @description Transform a charge or income in treasuryByMonth
     * @author ANDRE Felix
     * @private
     * @param {(ChargeEntity | IncomeEntity)} entity
     * @param {(PropertyEntity | SocietyEntity)} asset
     * @param {Date} startDate
     * @param {Date} endDate
     * @returns {*}
     * @memberof TreasuryList
     */
    private transformEntityInTreasury(
        entity: ChargeEntity | IncomeEntity,
        asset: PropertyEntity | SocietyEntity | undefined,
        currentStartDate: Date,
        currentEndDate: Date,
    ): TreasuryListModel<TCategory, TPeriodicity> {
        const treasury: TreasuryListModel<TCategory, TPeriodicity> = {
            categoryInfo: this.getCategoryInfo(entity.category),
            uid: entity.uid,
            amount: OmedomTreasury.getMonthAmount(entity, currentStartDate),
            date:
                entity.periodicity === 'punctual'
                    ? entity.debitDate?.toDate() ?? new Date(currentStartDate)
                    : OmedomTreasury.getMonthHistoryDate(entity, currentStartDate.toUTC()),
            isPayed: OmedomTreasury.isTreasuryPayed(entity, currentStartDate, currentEndDate),
            periodicityInfo: this.getPeriodicityInfo(entity.periodicity),
            designation: entity.designation ?? '',
            propertyName: asset?.name ?? '',
            propertyImg: asset?.photo ?? '',
            propertyOwnerUID: asset?.userUID ?? '',
            propertyUID: asset?.uid ?? '',
            withNotif: true,
            userUID: entity.userUID,
            notes: OmedomTreasury.getMonthNotes(entity, currentStartDate),
        };
        return treasury;
    }

    /**
     * @description Open a modal for a Story
     * @author ANDRE Felix
     * @protected
     * @template T
     * @param {Type<T>} type
     * @param {Story} stories
     * @returns {*}  {Promise<HTMLIonModalElement>}
     * @memberof TreasuryList
     */
    protected async showStoryModal<T>(type: Type<T>, story: Story): Promise<HTMLIonModalElement> {
        const treasuryByDayFlat = this.treasuryByDay.flatMap((x) => x.treasury);
        const selectedIndex = treasuryByDayFlat.findIndex((x) => x.uid === story.uid);
        const isCharge = story.isCharge;

        const storiesOfTreasury = treasuryByDayFlat.map((x) =>
            OmedomStory.transformTreasuryModelListToStory(
                x as ChargeListModel | IncomeListModel,
                isCharge,
            ),
        );

        this.storyModal = await this.modalController.create({
            component: type,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            canDismiss: true,
            componentProps: {
                stories: [...storiesOfTreasury],
                selectedIndex: selectedIndex,
                canNavigate: true,
            },
        });

        await this.storyModal.present();

        return this.storyModal;
    }

    async editClicked(treasury: Story): Promise<void> {
        this.editClickedEvent.emit(treasury);
        const treasuryString = treasury.isCharge ? 'charge' : 'income';
        const tresuryUpdateType = treasury.isCharge ? ChargeUpdateType : IncomeUpdateType;
        const treasuryPeriodicity = treasury.isCharge ? ChargePeriodicity : IncomePeriodicity;
        const isPunctual = treasury.periodicityInfo.periodicity === treasuryPeriodicity.punctual;
        if (isPunctual) {
            await this.modalController.dismiss();
            await this.modalController.dismiss({
                updateType: tresuryUpdateType.thisOneAndNext,
                treasuryUid: treasury.uid,
                currentDate: new Date(treasury.date),
                isCharge: treasury.isCharge,
            });
            return;
        }
        let modal: HTMLIonModalElement;
        if (treasury.isCharge) {
            modal = await this.showEditComponent(ChargeEditTypeComponent);
        } else {
            modal = await this.showEditComponent(IncomeEditTypeComponent);
        }

        await modal.present();
        modal.onDidDismiss().then(async (x) => {
            if (!x.data) {
                return;
            }

            if (this.propertyUid) {
                this.router.navigate([
                    `/tabs/property/info/${this.propertyUid}/${treasuryString}/edit/${treasury.uid}/${x.data}/${treasury.date}`,
                ]);
            } else {
                this.router.navigate([
                    `/tabs/treasury/${treasuryString}/edit/${treasury.uid}/${x.data}/${treasury.date}`,
                ]);
            }

            // Close all modal
            await this.modalController.dismiss({
                updateType: x.data,
                treasuryUid: treasury.uid,
                currentDate: new Date(treasury.date),
                isCharge: treasury.isCharge,
            });
        });
    }
    // async deleteClicked(treasury: Story) {}
}
