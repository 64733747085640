import '@omedom/extension';

import { DecimalPipe, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SubscriptionService, UserService } from '@omedom/services';
import { UiModule } from '@omedom/ui';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IncomeModule } from './income/income.module';
import { LevelModule } from './level/level.module';
import { LicenceModule } from './licence/licence.module';
import { TreasuryModule } from './treasury/treasury.module';

registerLocaleData(localeFr);

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        IncomeModule,
        TreasuryModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        AngularFireStorageModule,
        AngularFireAuthModule,
        AngularFireFunctionsModule,
        IncomeModule,
        LevelModule,
        LicenceModule,
        //provideMessaging(() => getMessaging()),

        UiModule,
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        {
            provide: APP_INITIALIZER,
            useFactory:
                (subscriptionService: SubscriptionService, userService: UserService) => () => {
                    const user$ = userService.user$;

                    return lastValueFrom(user$.pipe(take(1)))
                        .then((user) => {
                            if (user) {
                                return subscriptionService.updateLocalSubscription();
                            }
                        })
                        .catch((error) => {
                            console.error('Error during APP_INITIALIZER:', error);
                            return Promise.resolve();
                        });
                },
            deps: [SubscriptionService, UserService],
            multi: true,
        },
        DecimalPipe,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
