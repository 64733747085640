import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PropertiesFilter, SelectOption, UserEntity, startDateTarification } from '@omedom/data';
import { PropertyService, SocietyService, UserService } from '@omedom/services';
import { BehaviorSubject, Subscription } from 'rxjs';

import { SelectComponent } from '@omedom/ui';

@Component({
    selector: 'app-asset-filter',
    templateUrl: './asset-filter.component.html',
    styleUrls: ['./asset-filter.component.scss'],
})
export class AssetFilterComponent implements OnInit {
    @Input() disabled = false;

    /**
     * @description Display filter icon if authorized by user subscription plan
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 18/06/2024
     * @memberof AssetFilterComponent
     */
    @Input() canFilter = false;

    @Output() update = new EventEmitter();

    isOpen = false;

    /**
     * @description controls popover for properties
     * @author Hanane Djeddal
     * @memberof AssetFilterComponent
     */
    @ViewChild('popover') popover;

    @ViewChild('smartPopover') smartPopover;

    /**
     * @description the listing of properties for filtering
     * @author Hanane Djeddal
     * @type {SelectComponent}
     * @memberof AssetFilterComponent
     */
    @ViewChild('selectApp') selectApp: SelectComponent;

    selectedAssets: SelectOption[] = [];

    propertyOptions$ = new BehaviorSubject<SelectOption[]>([]);

    subjectPlaceholder = { label: 'Vos biens' } as SelectOption;

    allPropertiesOptions: SelectOption[] = [];

    private subscription: Subscription;

    private currentUser: UserEntity;

    /**
     * @description If the date is before the transition date (2024-03-01) or not
     * @author Brisset Killain
     * @type {boolean}
     * @memberof AssetFilterComponent
     */
    public isBeforeTransitionDate = false;

    constructor(
        private userService: UserService,
        private propertyService: PropertyService,
        private societyService: SocietyService
    ) { }

    async ngOnInit() {
        this.selectedAssets = PropertiesFilter.filteredProperties;
        this.subscription = this.userService.user$.subscribe(async (user) => {
            this.currentUser = user;
            const selectedUids = this.selectedAssets.map((x) => x.id);

            const propertieOptions = await this.propertyService.getUserPropertiesAndSharedOptions(
                user.uid
            );

            const societiesOptions = await this.societyService.getUserSocietiesAndSharedOptions(
                user.uid
            );

            const propertieOptionsFiltered = propertieOptions.filter((x) => x.isAccesible);

            this.allPropertiesOptions = [...propertieOptionsFiltered, ...societiesOptions];

            this.propertyOptions$.next(this.allPropertiesOptions);

            this.propertyOptions$.forEach((y) =>
                y.forEach((x) => {
                    if (selectedUids.includes(x.id)) {
                        x.isSelected = true;
                    }
                })
            );

            const transitionDate = new Date(startDateTarification);
            const today = new Date();
            this.isBeforeTransitionDate = today.getTime() < transitionDate.getTime();
        });
    }
    /**
     * @description event when property is selected from filter
     * @param property
     */
    async propertySelected(property: SelectOption) {
        this.popover?.dismiss();
        if (
            this.canFilter &&
            !this.disabled &&
            (property.isAccesible || this.isBeforeTransitionDate)
        ) {
            const index = this.selectedAssets.findIndex((element) => element.id === property.id);
            if (index >= 0) {
                this.selectedAssets.splice(index, 1);

                this.propertyOptions$.forEach((y) =>
                    y.forEach((x) => {
                        if (x.id === property.id) {
                            x.isSelected = false;
                        }
                    })
                );
            } else {
                this.selectedAssets.push(property);

                this.propertyOptions$.forEach((y) =>
                    y.forEach((x) => {
                        if (x.id === property.id) {
                            x.isSelected = true;
                        }
                    })
                );
            }
            PropertiesFilter.updateProperties(this.selectedAssets);
            this.update.emit();
        }
    }
    updateFilter() {
        PropertiesFilter.updateProperties(this.selectedAssets);
        this.update.emit();
    }
    /**
     * @description allow to open the select-app via the filter icon
     */
    openSelect(): void {
        // TODO filter is not working, i disabled it
        // this.selectApp.onClick();
    }
    presentSmartPopover(e: Event) {
        if (!this.canFilter) {
            this.smartPopover.event = e;
            this.isOpen = true;
        }
    }
}
