import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import {
    AllIncomeCategories,
    defaultRoleState,
    EntityTypes,
    IncomeCategoryInfo,
    IncomeCategoryProperty,
    IncomeEntity,
    IncomeListModel,
    IncomePeriodicity,
    IncomePeriodicityInfo,
    PropertyType,
    RoleState,
} from '@omedom/data';
import {
    AnalyticsService,
    IncomeService,
    PropertyService,
    RoleService,
    SocietyService,
    UserService,
} from '@omedom/services';
import { IncomeDeleteComponent, StoryDisplayComponent, TreasuryList } from '@omedom/ui';
import { OmedomStory } from '@omedom/utils';

@Component({
    selector: 'app-income-list',
    templateUrl: './income-list.component.html',
    styleUrls: ['./income-list.component.scss'],
})
export class IncomeListComponent extends TreasuryList<
    IncomeEntity,
    AllIncomeCategories,
    IncomePeriodicity
> {
    /**
     * @description: title of the page: "Revenus du mois" when in property; "Revenus globaux" when in treasury
     * @author: Hanane Djeddal.
     * @type {string}
     * @memberof IncomeListComponent
     */
    label: string;

    /**
     *@description: Only show property avatar card when in treasury
     * @author: Hanane Djeddal.
     * @type {boolean}
     * @memberof IncomeListComponent
     */
    avatar: boolean;

    /**
     * @description Previous url to assign to the back button
     * @author Jérémie Lopez
     * @type {string}
     * @memberof IncomeListComponent
     */
    previousUrl: string;
    modal: HTMLIonModalElement;

    propertyUid: string;

    roleRight = defaultRoleState as RoleState;

    constructor(
        userService: UserService,
        propertyService: PropertyService,
        societyService: SocietyService,
        activatedRoute: ActivatedRoute,
        modalController: ModalController,
        router: Router,
        private roleService: RoleService,
        private incomeService: IncomeService,
        private routerComp: Router,
        private analyticsService: AnalyticsService,
    ) {
        super(
            userService,
            propertyService,
            activatedRoute,
            modalController,
            router,
            societyService,
        );

        const segment = this.routerComp.url.split('/')[2];
        const isSociety = this.routerComp.url.split('/')[3];
        this.propertyUid = this.routerComp.url.split('/')[4];

        if (segment === 'treasury') {
            this.previousUrl = '/tabs/treasury';
        } else {
            if (isSociety === 'society') {
                const societyUid = this.routerComp.url.split('/')[5];
                this.previousUrl = '/tabs/property/society/info/' + societyUid;
            } else {
                this.previousUrl = '/tabs/property/info/' + this.propertyUid;
            }
        }
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit();
        const property = await this.propertyService.get(this.propertyUid);
        if (property) {
            this.roleRight = await this.roleService.getRoleState(property, EntityTypes.property);
        }
    }

    async ionViewWillEnter() {
        super.ionViewWillEnter();
    }
    ionViewDidEnter() {
        this.analyticsService.setCurrentScreen('Income List Page');
    }

    async deleteClicked(incomeListModel: IncomeListModel): Promise<void> {
        await this.showDeleteComponent(IncomeDeleteComponent, incomeListModel);
    }

    getCategoryInfo(category: string): IncomeCategoryInfo {
        return new IncomeCategoryInfo(category as IncomeCategoryProperty);
    }

    getPeriodicityInfo(periodicity: string): IncomePeriodicityInfo {
        return new IncomePeriodicityInfo(periodicity as IncomePeriodicity);
    }

    async loadTreasury(userUid: string): Promise<IncomeEntity[]> {
        let incomes: Promise<IncomeEntity[]>;

        this.label = this.propertyUid || this.societyUid ? 'Revenus du mois' : 'Revenus globaux';
        this.avatar = this.propertyUid ? false : true;

        if (!!this.propertyUid) {
            const currentProperty = await this.propertyService.get(this.propertyUid);
            this.avatar = currentProperty?.type === PropertyType.immeuble;
            if (
                (currentProperty.lotsUID && currentProperty.lotsUID.length > 0) ||
                currentProperty.type === PropertyType.immeuble
            ) {
                return this.incomeService.getIncomesByBuildingsLots(currentProperty);
            }
            return this.incomeService.getIncomesByProperty(this.propertyUid);
        }
        if (!!this.societyUid) {
            this.avatar = true;
            return this.incomeService.getIncomesBySociety(this.societyUid);
        }

        const propertiesCharges = await this.incomeService.getUserIncomesByPropertyIds(
            this.properties.map((property) => property.uid),
        );
        const societyCharges = await this.incomeService.getUserIncomesBySocietyIds(
            this.societies.map((society) => society.uid),
        );
        return [...propertiesCharges, ...societyCharges];
    }

    addClicked(): void {
        if (this.propertyUid) {
            this.routerComp.navigate([`/tabs/property/info/${this.propertyUid}/income/form`], {
                queryParams: {
                    list: 'true',
                },
            });
        } else {
            this.routerComp.navigate(['/tabs/treasury/income/form'], {
                queryParams: {
                    list: 'true',
                },
            });
        }
    }

    /**
     * @description Open modal for income story
     * @author ANDRE Felix
     * @param {IncomeListModel} chargeList
     * @memberof IncomeListComponent
     */
    async incomeClicked(incomeList: IncomeListModel): Promise<void> {
        this.analyticsService.logEvent('Story opened from income list');
        const storyModel = OmedomStory.transformTreasuryModelListToStory(incomeList, false);

        const modal = await this.showStoryModal(StoryDisplayComponent, storyModel);
        modal.onDidDismiss().then((callback) => {
            if (
                callback.data?.updateType &&
                callback.data?.treasuryUid &&
                callback.data?.currentDate
            ) {
                if (callback.data.isCharge) {
                    this.routerComp.navigate([
                        '/tabs/treasury/charge/edit/' +
                            callback.data.treasuryUid +
                            '/' +
                            callback.data.updateType +
                            '/' +
                            callback.data.currentDate,
                    ]);
                } else {
                    this.routerComp.navigate([
                        '/tabs/treasury/income/edit/' +
                            callback.data.treasuryUid +
                            '/' +
                            callback.data.updateType +
                            '/' +
                            callback.data.currentDate,
                    ]);
                }
            } else {
                this.updateData();
            }
        });
    }

    getIncomeInfo(propertyUID: string): {
        icon: string;
    } {
        const property = this.properties.find((p) => p.uid === propertyUID);
        const society = this.societies.find((s) => s.uid === propertyUID);
        let icon = 'uil uil-home';
        if (property) {
            icon = property.type === PropertyType.immeuble ? 'uil uil-building' : 'uil uil-home';
        } else if (society) {
            icon = 'uil uil-suitcase';
        }
        return {
            icon,
        };
    }
}
