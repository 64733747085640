import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import {
    AssetTypes,
    ChargeEntity,
    FilterParameter,
    IncomeEntity,
    ProEntity,
    PropertyEntity,
    PropertyTab,
    SocietyEntity,
    SortParameter,
    UserEntity,
} from '@omedom/data';
import {
    ChargeService,
    IncomeService,
    PropertyService,
    ProService,
    SocietyService,
    SubscriptionService,
    UserService,
} from '@omedom/services';
import { OmedomRentability } from '@omedom/utils';
import { BehaviorSubject, Subscription } from 'rxjs';

import { PropertyListPage } from '../../property/pages/property-list/property-list.page';
import { ShareConditionsComponent } from '@omedom/ui';
import { Router } from '@angular/router';

@Component({
    selector: 'app-property-list',
    templateUrl: './property-list.component.html',
    styleUrls: ['./property-list.component.scss'],
    animations: [
        trigger('list', [
            transition('* => *', [
                // each time the binding value changes
                query(
                    ':enter',
                    [
                        style({ opacity: 0, transform: 'translateY(30px)' }),
                        stagger(100, [
                            animate(
                                '0.5s',
                                style({
                                    opacity: 1,
                                    transform: 'translateY(0px)',
                                })
                            ),
                        ]),
                    ],
                    { optional: true }
                ),
            ]),
        ]),
    ],
})
export class PropertyListComponent implements OnInit, OnChanges, OnDestroy {
    /**
     * @description User data
     * @author Jérémie Lopez
     * @private
     * @type {UserEntity}
     * @memberof PropertyListComponent
     */
    public user: UserEntity;

    /**
     * @description List of properties owned by the user or shared to him
     * @author Jérémie Lopez
     * @type {PropertyEntity[]}
     * @memberof PropertyListComponent
     */
    @Input()
    public properties: PropertyEntity[] = [];

    /**
     * @description Show remove society button
     * @author Jérémie Lopez
     * @memberof PropertyListComponent
     */
    @Input() showRemoveSociety = false;

    /**
     * @description LIst of properties filtered
     * @author Jérémie Lopez
     * @type {PropertyEntity[]}
     * @memberof PropertyListComponent
     */
    public filteredProperties: PropertyEntity[] = [];

    /**
     * @description List of societies owned by the user or shared to him
     * @author Jérémie Lopez
     * @type {SocietyEntity[]}
     * @memberof PropertyListComponent
     */
    @Input()
    public societies: SocietyEntity[] = [];

    /**
     * @description If this property is Link to a building, and can be remove from it
     * @author ANDRE Felix
     * @memberof PropertyListComponent
     */
    @Input()
    public isLinkToBuilding = false;

    /**
     * @description display button to display only property, society, none, or both
     * @author ANDRE Felix
     * @memberof PropertyListComponent
     */
    @Input()
    withButtonFilterSociety = false;

    /**
     * @description Emit when need to refresh data from parent component
     * @author Jérémie Lopez
     * @memberof PropertyListComponent
     */
    @Output()
    public refresh = new EventEmitter();

    /**
     * @description List of societies filtered
     * @author Jérémie Lopez
     * @type {SocietyEntity[]}
     * @memberof PropertyListComponent
     */
    public filteredSocieties: SocietyEntity[] = [];

    /**
     * @description List of societies or properties owned by the user or shared to him
     * @author Jérémie Lopez
     * @type {((SocietyEntity | PropertyEntity)[])}
     * @memberof PropertyListComponent
     */
    public filteredEntities: (SocietyEntity | PropertyEntity)[] = [];

    /**
     * @description List of properties owned by the user and sharable with a pro
     * @author Didier Pascarel
     * @type (PropertyEntity)[]
     * @memberof PropertyListComponent
     */
    public filteredSelectableProperties: PropertyEntity[] = [];

    public filterData: FilterParameter[] = [];
    public sortData: SortParameter = { where: 'created', order: 'asc' };
    public searchData = '';
    private date = new Date().toUTC();

    public incomes: IncomeEntity[] = [];
    public charges: ChargeEntity[] = [];

    private subscriptions: Subscription[] = [];

    public listView = true;

    /**
     * @description Use to display or hide properties
     * @author ANDRE Felix
     * @memberof PropertyListComponent
     */
    displayProperty = true;

    /**
     * @description Use to display or hide societies
     * @author ANDRE Felix
     * @memberof PropertyListComponent
     */
    displaySociety = true;

    /**
     * @description determine if there is a least one property or society
     * @author ANDRE Felix
     * @memberof PropertyListComponent
     */
    hasAtLeastOneProperty = false;

    constructor(
        private incomeService: IncomeService,
        private chargeService: ChargeService,
        private propertyService: PropertyService,
        private userService: UserService,
        private router: Router
    ) {}

    async ngOnInit(): Promise<void> {
        const user$ = this.userService.user$.subscribe(async (user) => {
            this.user = user;

            if (this.user) {
                // used to display properties and societies in list or cards
                this.listView =
                    this.user.displayPropertyList !== undefined
                        ? this.user.displayPropertyList
                        : true;
                this.charges = await this.chargeService.getUserCharges(this.user.uid);
                this.incomes = await this.incomeService.getUserIncomes(this.user.uid);

                this.updateData();
            }
        });
        this.subscriptions.push(user$);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.properties?.currentValue) {
            this.properties = changes.properties.currentValue;
        }

        if (changes.societies?.currentValue) {
            this.societies = changes.societies.currentValue;
        }
        // I put it here, because in ngOninit, property are empty
        this.hasAtLeastOneProperty = this.properties.length > 0 || this.societies.length > 0;

        this.updateData();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    /**
     * @description Filter data
     * @author Jérémie Lopez
     * @param {string} value
     * @memberof PropertyListComponent
     */
    private updateData(): void {
        this.filteredProperties = [];
        this.filteredSocieties = [];

        this.filteredEntities = [];

        if (this.displayProperty) {
            this.filteredProperties = this.filterAsset(this.properties) as PropertyEntity[];
        }
        if (this.displaySociety) {
            this.filteredSocieties = this.filterAsset(this.societies) as SocietyEntity[];
        }

        this.filteredEntities = [
            ...this.filteredProperties.map((property) => {
                (property as any).entityType = 'property';
                return property;
            }),
            ...this.filteredSocieties.map((society) => {
                (society as any).entityType = 'society';
                return society;
            }),
        ];

        this.filterData.forEach((filter) => {
            if (filter.where === 'entityType') {
                this.filteredEntities = this.filteredEntities.filter(
                    (element: any) => element.entityType === filter.value
                );
            }

            if (filter.where === 'type') {
                this.filteredEntities = this.filteredEntities.filter(
                    (element) => element.type === filter.value
                );
            }

            if (filter.where === 'rentability') {
                // TODO: filter by rentability
            }
        });

        this.filteredEntities.sort((a, b) => {
            let result = 0;
            switch (this.sortData.where) {
                case 'name':
                    result =
                        a.name.localeCompare(b.name) * (this.sortData.order === 'asc' ? 1 : -1);
                    break;

                case 'created':
                    const date1 = new Date(a.created);
                    const date2 = new Date(b.created);
                    result =
                        (date1.getTime() - date2.getTime()) *
                        (this.sortData.order === 'asc' ? 1 : -1);
                    break;

                case 'rentability':
                    const incomesA = this.incomes?.filter(
                        (x) => x[(a as any).entityType + 'UID'] === a.uid
                    );
                    const incomesB = this.incomes?.filter(
                        (x) => x[(b as any).entityType + 'UID'] === b.uid
                    );
                    let rentabilityA: number;
                    let rentabilityB: number;

                    if ((a as any).entityType === 'property') {
                        rentabilityA = OmedomRentability.getPropertyGrossRentability(
                            a as PropertyEntity,
                            incomesA,
                            this.date
                        );
                    } else {
                        this.propertyService.getPropertiesBySociety(a.uid).then((properties) => {
                            rentabilityA = OmedomRentability.getPropertiesGrossRentability(
                                properties,
                                incomesA,
                                this.date
                            );
                        });
                    }

                    if ((b as any).entityType === 'property') {
                        rentabilityB = OmedomRentability.getPropertyGrossRentability(
                            b as PropertyEntity,
                            incomesA,
                            this.date
                        );
                    } else {
                        this.propertyService.getPropertiesBySociety(b.uid).then((properties) => {
                            rentabilityB = OmedomRentability.getPropertiesGrossRentability(
                                properties,
                                incomesB,
                                this.date
                            );
                        });
                    }

                    result = rentabilityA - rentabilityB * (this.sortData.order === 'asc' ? 1 : -1);
                    break;

                default:
                    result = b.created.getTime() - a.created.getTime();
                    break;
            }

            return result;
        });

        const accessibleAssets = [];
        const inaccessibleAssets = [];

        this.filteredEntities.forEach((asset) => {
            // const assetOption = asset.toSelectOption();
            // TODO Temporary solution
            const assetOption = {
                label: asset.name,
                image: asset.photo,
                icon: asset.photo ? undefined : 'uil uil-suitcase',
                assetType: AssetTypes.property,
            };
            if (
                assetOption.assetType === AssetTypes.property ||
                assetOption.assetType === AssetTypes.building
            ) {
                if (this.propertyService.isPropertyAccessible(asset as PropertyEntity)) {
                    accessibleAssets.push(asset);
                } else {
                    inaccessibleAssets.push(asset);
                }
            } else {
                accessibleAssets.push(asset);
            }
        });

        this.filteredEntities = [...accessibleAssets, ...inaccessibleAssets];
    }

    filterAsset(assets: (PropertyEntity | SocietyEntity)[]) {
        return assets.filter((element) => {
            const streetNumberFiltered = element?.address?.streetNumber
                ? element?.address?.streetNumber.toString().includes(this.searchData)
                : false;

            return (
                streetNumberFiltered ||
                element?.address?.city?.toLowerCase()?.includes(this.searchData) ||
                element?.address?.postalCode?.toLowerCase()?.includes(this.searchData) ||
                element?.address?.street?.toLowerCase()?.includes(this.searchData) ||
                element?.name?.toLowerCase()?.includes(this.searchData)
            );
        });
    }

    /**
     * @description Refresh user preference in the propertis ans societies list
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @memberof PropertyListComponent
     */
    async changeDisplay(): Promise<void> {
        this.user.displayPropertyList = this.listView;

        await this.userService.update(this.user);
    }

    public filter(filter: FilterParameter[]): void {
        this.filterData = filter;
        this.updateData();
    }

    public sort(sort: SortParameter): void {
        this.sortData = sort;
        this.updateData();
    }

    public search(value: string): void {
        this.searchData = value;
        this.updateData();
    }

    public togglePropertyFilter() {
        if (this.displayProperty && !this.displaySociety) {
            this.displaySociety = !this.displaySociety;
        }
        this.displayProperty = !this.displayProperty;
        this.updateData();
    }

    public toggleSocietyFilter() {
        if (!this.displayProperty && this.displaySociety) {
            this.displayProperty = !this.displayProperty;
        }
        this.displaySociety = !this.displaySociety;
        this.updateData();
    }

    protected propertyClicked(asset: PropertyEntity | SocietyEntity): void {
        this.router.navigate(['/tabs/property/info/', asset.uid], {
            queryParams: { tab: PropertyTab.info },
        });
    }

    protected societyClicked(asset: PropertyEntity | SocietyEntity): void {
        this.router.navigate(['/tabs/property/society/info/', asset.uid]);
    }
}
