// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.omedom-page-container-content {
  box-shadow: none !important;
  padding: 0 !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxvYW4tZm9ybS5jb250YWluZXIuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLDJCQUFBO0VBQ0EscUJBQUE7QUFDSiIsImZpbGUiOiJsb2FuLWZvcm0uY29udGFpbmVyLnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIub21lZG9tLXBhZ2UtY29udGFpbmVyLWNvbnRlbnQge1xuICAgIGJveC1zaGFkb3c6IG5vbmUgIWltcG9ydGFudDtcbiAgICBwYWRkaW5nOiAwICFpbXBvcnRhbnQ7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/containers/loan/loan-form/loan-form.container.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;EACA,qBAAA;AACJ;AACA,oZAAoZ","sourcesContent":[".omedom-page-container-content {\n    box-shadow: none !important;\n    padding: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
