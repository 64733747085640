import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FilterParameter, SortParameter } from '@omedom/data';
import {
    PropertySocietySortComponent,
    // PropertySocietyFilterComponent,
} from '@omedom/ui';

@Component({
    selector: 'app-property-search',
    templateUrl: './property-search.component.html',
    styleUrls: ['./property-search.component.scss'],
})
export class PropertySearchComponent {
    /**
     * @description Search data string
     * @author Jérémie Lopez
     * @type {string}
     * @memberof PropertySearchComponent
     */
    public searchData: string;

    /**
     * @description Filter data
     * @author Jérémie Lopez
     * @type {FilterParameter[]}
     * @memberof PropertySearchComponent
     */
    @Input()
    public filterData: FilterParameter[] = [];

    /**
     * @description Sort data
     * @author Jérémie Lopez
     * @type {SortParameter[]}
     * @memberof PropertySearchComponent
     */
    @Input()
    public sortData: SortParameter;

    /**
     * @description Send data input
     * @author Jérémie Lopez
     * @memberof PropertySearchComponent
     */
    @Output()
    public search = new EventEmitter<string>();

    /**
     * @description Send filter
     * @author Jérémie Lopez
     * @memberof PropertySearchComponent
     */
    @Output()
    public filter = new EventEmitter<FilterParameter[]>();

    /**
     * @description Send sort
     * @author Jérémie Lopez
     * @memberof PropertySearchComponent
     */
    @Output()
    public sort = new EventEmitter<SortParameter>();

    constructor(private modalController: ModalController) {}

    /**
     * @description Send data to parent component
     * @author Jérémie Lopez
     * @memberof PropertySearchComponent
     */
    public sendSearch(): void {
        const input = this.searchData.toLowerCase();
        this.search.emit(input);
    }

    // /**
    //  * @description Open filter modal
    //  * @author Jérémie Lopez
    //  * @return {*}  {Promise<void>}
    //  * @memberof PropertySearchComponent
    //  */
    // public async openFilter(): Promise<void> {
    //     const entityType = this.filterData.find(
    //         (element) => element.where === 'entityType'
    //     );
    //     const type = this.filterData.find(
    //         (element) => element.where === 'type'
    //     );
    //     const rentability = this.filterData.find(
    //         (element) => element.where === 'rentability'
    //     );

    //     // Open modal
    //     const modal = await this.modalController.create({
    //         component: PropertySocietyFilterComponent,
    //         breakpoints: [0, 1],
    //         initialBreakpoint: 1,
    //         componentProps: {
    //             entityType: entityType ? entityType.value : null,
    //             type: type
    //                 ? ({ id: type.value, label: type.value } as SelectOption)
    //                 : null,
    //             rentability: rentability ? rentability.value : null,
    //         },
    //     });
    //     await modal.present();

    //     // Retrieve data from modal
    //     const { data } = await modal.onWillDismiss();

    //     if (data?.filter) {
    //         // Assign data to filterData
    //         this.filterData = data.filter;

    //         // Send filter event
    //         this.filter.emit(this.filterData);
    //     }
    // }

    /**
     * @description Open sort modal
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof PropertySearchComponent
     */
    public async openSort(): Promise<void> {
        // Open modal
        const modal = await this.modalController.create({
            component: PropertySocietySortComponent,
            breakpoints: [0, 1],
            initialBreakpoint: 1,
            canDismiss: true,
            componentProps: {
                selectedOption: this.sortData ?? ({ where: 'name', order: 'asc' } as SortParameter),
            },
        });
        await modal.present();

        // Retrieve data from modal
        const { data } = await modal.onWillDismiss();

        if (data?.sort) {
            // Assign data to sortData
            this.sortData = data.sort;

            // Send sort event
            this.sort.emit(this.sortData);
        }
    }
}
