import { Component, Input } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

import { OmedomRadioOption } from '../radio';

@Component({
    selector: 'app-property-saved',
    templateUrl: './property-saved.component.html',
    styleUrls: ['./property-saved.component.scss'],
})
export class PropertySavedComponent {
    @Input() avatar: string;

    @Input() title: string;

    packageOptions: OmedomRadioOption[] = [
        new OmedomRadioOption({ id: true, label: 'Essential' }),
        new OmedomRadioOption({ id: false, label: 'Smart' }),
    ];

    package = true;

    /**
     * @description Display essential package info after collapse
     * @author Hanane Djeddal
     * @memberof PropertySavedComponent
     */
    public displayEssentialInfo = new BehaviorSubject<boolean>(false);

    /**
     * @description Display smart package info after collapse
     * @author Hanane Djeddal
     * @memberof PropertySavedComponent
     */
    public displaySmartInfo = new BehaviorSubject<boolean>(true);

    constructor(
        private modalController: ModalController,
        private navController: NavController,
    ) { }

    async dismiss(): Promise<void> {
        await this.modalController.dismiss();
    }

    back() {
        this.dismiss();
        this.navController.pop();
    }

    async save(): Promise<void> {
        // Package chosen: Essential
        if (this.package) {
            await this.modalController.dismiss('saved');
        } else {
            await this.modalController.dismiss('saved-with-smart');
            // redirect to payPlug
            this.navController.navigateForward('/tabs/menu/subscription', { queryParams: { tab: 'manage' } });
        }
    }
}
