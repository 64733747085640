// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
}

.search {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.search button {
  margin: 0;
}
.search omedom-input {
  flex: 1;
}

.list {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRvY3VtZW50cy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGFBQUE7RUFDQSxzQkFBQTtFQUNBLFNBQUE7RUFDQSxZQUFBO0FBQ0o7O0FBRUE7RUFDSSxhQUFBO0VBQ0EsbUJBQUE7RUFDQSwyQkFBQTtFQUNBLFNBQUE7QUFDSjtBQUNJO0VBQ0ksU0FBQTtBQUNSO0FBRUk7RUFDSSxPQUFBO0FBQVI7O0FBSUE7RUFDSSxPQUFBO0VBQ0EsYUFBQTtFQUNBLDREQUFBO0VBQ0EsU0FBQTtBQURKIiwiZmlsZSI6ImRvY3VtZW50cy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAgZ2FwOiAyMHB4O1xuICAgIGhlaWdodDogMTAwJTtcbn1cblxuLnNlYXJjaCB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIGp1c3RpZnktY29udGVudDogZmxleC1zdGFydDtcbiAgICBnYXA6IDIwcHg7XG5cbiAgICBidXR0b24ge1xuICAgICAgICBtYXJnaW46IDA7XG4gICAgfVxuXG4gICAgb21lZG9tLWlucHV0IHtcbiAgICAgICAgZmxleDogMTtcbiAgICB9XG59XG5cbi5saXN0IHtcbiAgICBmbGV4OiAxO1xuICAgIGRpc3BsYXk6IGdyaWQ7XG4gICAgZ3JpZC10ZW1wbGF0ZS1jb2x1bW5zOiByZXBlYXQoYXV0by1maWxsLCBtaW5tYXgoMjAwcHgsIDFmcikpO1xuICAgIGdhcDogMjBweDtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/containers/property/property-profil/documents/documents.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,YAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,SAAA;AACJ;AACI;EACI,SAAA;AACR;AAEI;EACI,OAAA;AAAR;;AAIA;EACI,OAAA;EACA,aAAA;EACA,4DAAA;EACA,SAAA;AADJ;AACA,wiCAAwiC","sourcesContent":[":host {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    height: 100%;\n}\n\n.search {\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    gap: 20px;\n\n    button {\n        margin: 0;\n    }\n\n    omedom-input {\n        flex: 1;\n    }\n}\n\n.list {\n    flex: 1;\n    display: grid;\n    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));\n    gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
