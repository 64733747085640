import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import {
    AllIncomeCategories,
    defaultRoleState,
    EntityTypes,
    IncomeCategoryInfo,
    IncomeCategoryProperty,
    IncomeEntity,
    IncomeListModel,
    IncomePeriodicity,
    IncomePeriodicityInfo,
    PropertyType,
    RoleState,
} from '@omedom/data';
import {
    AnalyticsService,
    IncomeService,
    PropertyService,
    RoleService,
    SocietyService,
    UserService,
} from '@omedom/services';
import { OmedomStory } from '@omedom/utils';
import { BehaviorSubject } from 'rxjs';

import { elementAnimation, listAnimation, transactionAnimation } from '../../animations';
import { TreasuryList } from '../../class/treasury-list';
import { IncomeDeleteComponent } from '../../components/income-delete/income-delete.component';
import { StoryDisplayComponent } from '../story-display/story-display.component';
import { TreasuryEditComponent } from '../treasury-edit/treasury-edit.component';

@Component({
    selector: 'omedom-income-list',
    templateUrl: './income-list.component.html',
    styleUrls: ['./income-list.component.scss'],
    animations: [listAnimation, transactionAnimation, elementAnimation],
})
export class IncomeListComponent extends TreasuryList<
    IncomeEntity,
    AllIncomeCategories,
    IncomePeriodicity
> {
    /**
     * @description: title of the page: "Revenus du mois" when in property; "Revenus globaux" when in treasury
     * @author: Hanane Djeddal.
     * @type {string}
     * @memberof IncomeListComponent
     */
    label?: string;

    /**
     * @description Label if the component is used in a modal
     * @author Brisset Killian
     * @date 07/06/2024
     * @type {string}
     * @memberof ChargeListComponent
     */
    @Input() modalLabel?: string;

    /**
     *@description: Only show property avatar card when in treasury
     * @author: Hanane Djeddal.
     * @type {boolean}
     * @memberof IncomeListComponent
     */
    avatar?: boolean;

    /**
     * @description Previous url to assign to the back button
     * @author Jérémie Lopez
     * @type {string}
     * @memberof IncomeListComponent
     */
    previousUrl: string;
    modal?: HTMLIonModalElement;

    override propertyUid?: string;

    roleRight = defaultRoleState as RoleState;

    @Input()
    incomes$: BehaviorSubject<IncomeEntity[]> = new BehaviorSubject<IncomeEntity[]>([]);

    @Input()
    showAllIncomes = true;

    @Output()
    treasuryEditEvent: EventEmitter<{
        isCharge: boolean;
        currentDate: Date;
        treasuryUid: string;
        updateType: number;
    }> = new EventEmitter();

    constructor(
        userService: UserService,
        propertyService: PropertyService,
        societyService: SocietyService,
        activatedRoute: ActivatedRoute,
        modalController: ModalController,
        router: Router,
        private roleService: RoleService,
        private incomeService: IncomeService,
        private routerComp: Router,
        private analyticsService: AnalyticsService,
    ) {
        super(
            userService,
            propertyService,
            activatedRoute,
            modalController,
            router,
            societyService,
        );

        const segment = this.routerComp.url.split('/')[2];
        const isSociety = this.routerComp.url.split('/')[3];
        this.propertyUid = this.routerComp.url.split('/')[4];

        if (segment === 'treasury') {
            this.previousUrl = '/tabs/treasury';
        } else {
            if (isSociety === 'society') {
                const societyUid = this.routerComp.url.split('/')[5];
                this.previousUrl = '/tabs/property/society/info/' + societyUid;
            } else {
                this.previousUrl = '/tabs/property/info/' + this.propertyUid;
            }
        }
    }

    override async ngOnInit(): Promise<void> {
        super.ngOnInit();
        if (!this.propertyUid) {
            return;
        }
        const property = await this.propertyService.get(this.propertyUid);
        if (property) {
            this.roleRight = await this.roleService.getRoleState(property, EntityTypes.property);
        }
    }

    override async ionViewWillEnter() {
        super.ionViewWillEnter();
    }
    ionViewDidEnter() {
        this.analyticsService.setCurrentScreen('Income List Page');
    }

    async deleteClicked(incomeListModel: IncomeListModel): Promise<void> {
        await this.showDeleteComponent(IncomeDeleteComponent, incomeListModel);
    }

    getCategoryInfo(category: string): IncomeCategoryInfo {
        return new IncomeCategoryInfo(category as IncomeCategoryProperty);
    }

    getPeriodicityInfo(periodicity: string): IncomePeriodicityInfo {
        return new IncomePeriodicityInfo(periodicity as IncomePeriodicity);
    }

    async loadTreasury(userUid: string): Promise<IncomeEntity[]> {
        let incomes: Promise<IncomeEntity[]>;

        this.label =
            this.propertyUid || this.societyUid || !this.showAllIncomes
                ? 'Revenus du mois'
                : 'Revenus globaux';
        this.avatar = this.propertyUid ? false : true;
        if (this.incomes$.value.length > 0) {
            return this.incomes$.value;
        }

        if (!!this.propertyUid) {
            const currentProperty = await this.propertyService.get(this.propertyUid);
            this.avatar = currentProperty?.type === PropertyType.immeuble;
            if (
                (currentProperty?.lotsUID && currentProperty.lotsUID.length > 0) ||
                currentProperty?.type === PropertyType.immeuble
            ) {
                return this.incomeService.getIncomesByBuildingsLots(currentProperty);
            }
            return this.incomeService.getIncomesByProperty(this.propertyUid);
        }
        if (!!this.societyUid) {
            this.avatar = true;
            return this.incomeService.getIncomesBySociety(this.societyUid);
        }

        if (this.showAllIncomes) {
            const propertiesCharges = await this.incomeService.getUserIncomesByPropertyIds(
                this.properties.map((property) => property.uid),
            );
            const societyCharges = await this.incomeService.getUserIncomesBySocietyIds(
                this.societies.map((society) => society.uid),
            );
            return [...propertiesCharges, ...societyCharges];
        }
        return [];
    }

    addClicked(): void {
        if (this.propertyUid) {
            this.routerComp.navigate([`/tabs/property/info/${this.propertyUid}/income/form`], {
                queryParams: {
                    list: 'true',
                },
            });
        } else {
            this.routerComp.navigate(['/tabs/treasury/income/form'], {
                queryParams: {
                    list: 'true',
                },
            });
        }
    }

    /**
     * @description Open modal for income story
     * @author ANDRE Felix
     * @param {IncomeListModel} chargeList
     * @memberof IncomeListComponent
     */
    async incomeClicked(incomeList: IncomeListModel): Promise<void> {
        this.analyticsService.logEvent('Story opened from income list');
        const storyModel = OmedomStory.transformTreasuryModelListToStory(incomeList, false);
        const modal = await this.showStoryModal(StoryDisplayComponent, storyModel);
        modal.onDidDismiss().then(async (callback) => {
            if (
                callback.data?.updateType &&
                callback.data?.treasuryUid &&
                callback.data?.currentDate
            ) {
                if (await this.modalController.getTop()) {
                    const editModal = await this.modalController.create({
                        component: TreasuryEditComponent,
                        initialBreakpoint: 1,
                        breakpoints: [0, 1],
                        canDismiss: true,
                        componentProps: {
                            updateType: callback.data.updateType,
                            treasuryUid: callback.data.treasuryUid,
                            currentDate: callback.data.currentDate,
                            isCharge: callback.data.isCharge,
                        },
                        cssClass: 'max-heigth-mode',
                    });

                    await editModal.present();

                    await editModal.onDidDismiss().then(() => {
                        this.updateData();
                    });
                } else {
                    this.treasuryEditEvent.emit({
                        isCharge: callback.data.isCharge ?? false,
                        currentDate: callback.data.currentDate,
                        treasuryUid: callback.data.treasuryUid,
                        updateType: callback.data.updateType,
                    });
                }
            } else {
                this.updateData();
            }
        });
    }

    getIncomeInfo(propertyUID: string): {
        icon: string;
    } {
        const property = this.properties.find((p) => p.uid === propertyUID);
        const society = this.societies.find((s) => s.uid === propertyUID);
        let icon = 'uil uil-home';
        if (property) {
            icon = property.type === PropertyType.immeuble ? 'uil uil-building' : 'uil uil-home';
        } else if (society) {
            icon = 'uil uil-suitcase';
        }
        return {
            icon,
        };
    }
}
