import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-canvas-header',
    templateUrl: './canvas-header.component.html',
    styleUrls: ['./canvas-header.component.scss'],
})
export class CanvasHeaderComponent {
    @Input() isCharge: boolean;

    @Input() totalAmount: number;

    @Input() totalAmountPayed: number;

    @Input() totalAmountNotPayed: number;
}
