// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  padding: 40px;
}

.modal {
  margin: 0;
  max-height: 87vh !important;
  box-sizing: border-box;
  overflow-y: auto;
  height: 87vh !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInJlY2NvbWVuZC1wcm8uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxhQUFBO0FBQ0o7O0FBRUE7RUFHSSxTQUFBO0VBQ0EsMkJBQUE7RUFDQSxzQkFBQTtFQUNBLGdCQUFBO0VBQ0EsdUJBQUE7QUFESiIsImZpbGUiOiJyZWNjb21lbmQtcHJvLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNvbnRhaW5lciB7XG4gICAgcGFkZGluZzogNDBweDtcbn1cblxuLm1vZGFsIHtcbiAgICAvLyBiYWNrZ3JvdW5kOiAjZWVlZWVlO1xuXG4gICAgbWFyZ2luOiAwO1xuICAgIG1heC1oZWlnaHQ6IDg3dmggIWltcG9ydGFudDtcbiAgICBib3gtc2l6aW5nOiBib3JkZXItYm94O1xuICAgIG92ZXJmbG93LXk6IGF1dG87XG4gICAgaGVpZ2h0OiA4N3ZoICFpbXBvcnRhbnQ7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/components/reccomend-pro/reccomend-pro.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEA;EAGI,SAAA;EACA,2BAAA;EACA,sBAAA;EACA,gBAAA;EACA,uBAAA;AADJ;AACA,goBAAgoB","sourcesContent":[".container {\n    padding: 40px;\n}\n\n.modal {\n    // background: #eeeeee;\n\n    margin: 0;\n    max-height: 87vh !important;\n    box-sizing: border-box;\n    overflow-y: auto;\n    height: 87vh !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
