import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewDidEnter } from '@ionic/angular';
import {
    allRoleState,
    ChargeCategoryProperty,
    ChargeEntity,
    IncomeCategoryProperty,
    IncomeEntity,
    PropertiesFilter,
    PropertyEntity,
    PropertyTab,
    RoleState,
    SocietyEntity,
    StringDateInterval,
    UserEntity,
} from '@omedom/data';
import {
    AnalyticsService,
    ChargeService,
    IncomeService,
    PropertyService,
    RefreshService,
    SmartService,
    SocietyService,
    UserService,
} from '@omedom/services';
import { combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { OmedomTab, OmedomTabType } from '../../../component/tab';

@Component({
    templateUrl: './treasury-home.page.html',
    styleUrls: ['./treasury-home.page.scss'],
    providers: [RefreshService],
})
export class TreasuryHomePage implements ViewDidEnter, OnInit {
    /**
     * @description Active step in the content
     * @author Jérémie Lopez
     * @memberof TreasuryHomePage
     */
    activeStep = 'charge';

    tabs: OmedomTab[] = [
        new OmedomTab({
            id: PropertyTab.charge,
            label: 'Charges',
            icon: 'uil uil-chart-pie-alt',
            type: OmedomTabType.red,
        }),
        new OmedomTab({
            id: PropertyTab.income,
            label: 'Revenus',
            icon: 'uil uil-euro-circle',
            type: OmedomTabType.green,
        }),
        new OmedomTab({
            id: PropertyTab.liquidity,
            label: 'Trésorerie',
            icon: 'uil uil-graph-bar',
            type: OmedomTabType.yellow,
        }),
    ];

    selectedTab: OmedomTab = this.tabs[0];

    propertyTab = PropertyTab;

    user: UserEntity;

    properties: PropertyEntity[] = [];

    societies: SocietyEntity[] = [];

    charges: ChargeEntity[] = [];

    incomes: IncomeEntity[] = [];

    roleState: RoleState = allRoleState;

    constructor(
        private refreshService: RefreshService,
        private route: ActivatedRoute,
        private router: Router,
        private analyticsService: AnalyticsService,
        private userService: UserService,
        private propertyService: PropertyService,
        private societyService: SocietyService,
        private chargeService: ChargeService,
        private incomeService: IncomeService,
        private smartService: SmartService,
    ) {}

    ngOnInit(): void {
        const tab = this.route.snapshot.paramMap.get('tab');
        this.selectedTab = tab === 'income' ? this.tabs[1] : this.tabs[0];
        this.userService.user$.subscribe((user) => {
            if (user?.uid) {
                this.user = user;
                this.updateData();
            }
        });
    }

    updateData() {
        const properties$ = this.propertyService._getUserPropertiesAndSharedAccessible(
            this.user.uid,
        );

        // Get societies and shared societies if user is smart
        // If user is not smart, only get societies where he is not the owner
        // Even shared societies are not shown if their owner is not smart
        const societies$ = this.smartService.hasSmart$.pipe(
            switchMap((hasSmart) => {
                return this.societyService._getUserSocietiesAndShared(this.user.uid).pipe(
                    map((societies) => {
                        return societies.filter((society) => {
                            if (hasSmart) {
                                return true;
                            }

                            return society.userUID !== this.user.uid;
                        });
                    }),
                );
            }),
        );

        const charges$ = this.chargeService._getUserCharges(this.user.uid);

        const incomes$ = this.incomeService._getUserIncomes(this.user.uid);

        combineLatest([properties$, societies$, charges$, incomes$]).subscribe(
            ([properties, societies, charges, incomes]) => {
                this.properties = properties ?? [];
                this.societies = societies ?? [];
                this.charges = charges ?? [];
                this.incomes = incomes ?? [];
            },
        );
    }

    ionViewDidEnter() {
        this.analyticsService.setCurrentScreen('Treasury Page');
        this.refreshService.viewDidEnter$.next();
    }

    public navigateToTreasuryDetailEvent(isCharge: boolean, dateISO: StringDateInterval): void {
        const treasuryName = isCharge ? 'charge' : 'income';
        this.router.navigate([`${treasuryName}/list/${dateISO.startDate}/${dateISO.endDate}`], {
            relativeTo: this.route,
            fragment: this.roleState?.update ? 'edit' : 'view',
        });
    }

    public navigateToAddTreasuryEvent(isCharge: boolean): void {
        const treasuryName = isCharge ? 'charge' : 'income';
        this.router.navigate([`${treasuryName}/form`], {
            relativeTo: this.route,
        });
    }

    public navigateToCategoryDetailEvent(
        isCharge: boolean,
        event: {
            category: ChargeCategoryProperty | IncomeCategoryProperty;
            dateInterval: StringDateInterval;
        },
    ): void {
        const treasuryName = isCharge ? 'charge' : 'income';
        const treasuryCategory = isCharge
            ? {
                  charge: event.category,
              }
            : {
                  income: event.category,
              };

        const selectedProperties = PropertiesFilter.getFilteredProperties();
        const selectedSocieties = PropertiesFilter.getFilteredSocieties();
        this.router.navigate([`/tabs/treasury/${treasuryName}/info`], {
            relativeTo: this.route,
            queryParams: {
                ...treasuryCategory,
                properties: selectedProperties,
                societies: selectedSocieties,
                startDate: event.dateInterval.startDate,
                endDate: event.dateInterval.endDate,
            },
        });
    }
}
