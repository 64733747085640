// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: 100%;
}

.modal {
  margin: 0;
  max-height: 87vh !important;
  box-sizing: border-box;
  overflow-y: auto;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNlbGVjdC1ib3R0b20tc2hlZXQuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxjQUFBO0VBQ0EsV0FBQTtBQUNKOztBQUVBO0VBRUksU0FBQTtFQUNBLDJCQUFBO0VBQ0Esc0JBQUE7RUFDQSxnQkFBQTtBQUFKIiwiZmlsZSI6InNlbGVjdC1ib3R0b20tc2hlZXQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG4gICAgZGlzcGxheTogYmxvY2s7XG4gICAgd2lkdGg6IDEwMCU7XG59XG5cbi5tb2RhbCB7XG4gICAgLy8gYmFja2dyb3VuZDogI2VlZWVlZTtcbiAgICBtYXJnaW46IDA7XG4gICAgbWF4LWhlaWdodDogODd2aCAhaW1wb3J0YW50O1xuICAgIGJveC1zaXppbmc6IGJvcmRlci1ib3g7XG4gICAgb3ZlcmZsb3cteTogYXV0bztcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./apps/app/src/app/component/select/bottom-sheet/select-bottom-sheet.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,WAAA;AACJ;;AAEA;EAEI,SAAA;EACA,2BAAA;EACA,sBAAA;EACA,gBAAA;AAAJ;AACA,wnBAAwnB","sourcesContent":[":host {\n    display: block;\n    width: 100%;\n}\n\n.modal {\n    // background: #eeeeee;\n    margin: 0;\n    max-height: 87vh !important;\n    box-sizing: border-box;\n    overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
