// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.omedom-page-container-content {
  box-shadow: none !important;
  padding: 0 !important;
}

omedom-stepper::ng-deep .stepper-header {
  min-height: min-content;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxvYW4tZm9ybS5jb250YWluZXIuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLDJCQUFBO0VBQ0EscUJBQUE7QUFDSjs7QUFFQTtFQUNJLHVCQUFBO0FBQ0oiLCJmaWxlIjoibG9hbi1mb3JtLmNvbnRhaW5lci5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLm9tZWRvbS1wYWdlLWNvbnRhaW5lci1jb250ZW50IHtcbiAgICBib3gtc2hhZG93OiBub25lICFpbXBvcnRhbnQ7XG4gICAgcGFkZGluZzogMCAhaW1wb3J0YW50O1xufVxuXG5vbWVkb20tc3RlcHBlcjo6bmctZGVlcCAuc3RlcHBlci1oZWFkZXIge1xuICAgIG1pbi1oZWlnaHQ6IG1pbi1jb250ZW50O1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/containers/loan/loan-form/loan-form.container.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;EACA,qBAAA;AACJ;;AAEA;EACI,uBAAA;AACJ;AACA,whBAAwhB","sourcesContent":[".omedom-page-container-content {\n    box-shadow: none !important;\n    padding: 0 !important;\n}\n\nomedom-stepper::ng-deep .stepper-header {\n    min-height: min-content;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
