// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.omedom-page-container-content {
  box-shadow: none !important;
  padding: 0 !important;
}

omedom-stepper::ng-deep .stepper-header {
  min-height: min-content;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNhdmluZy1mb3JtLmNvbnRhaW5lci5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksMkJBQUE7RUFDQSxxQkFBQTtBQUNKOztBQUVBO0VBQ0ksdUJBQUE7QUFDSiIsImZpbGUiOiJzYXZpbmctZm9ybS5jb250YWluZXIuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5vbWVkb20tcGFnZS1jb250YWluZXItY29udGVudCB7XG4gICAgYm94LXNoYWRvdzogbm9uZSAhaW1wb3J0YW50O1xuICAgIHBhZGRpbmc6IDAgIWltcG9ydGFudDtcbn1cblxub21lZG9tLXN0ZXBwZXI6Om5nLWRlZXAgLnN0ZXBwZXItaGVhZGVyIHtcbiAgICBtaW4taGVpZ2h0OiBtaW4tY29udGVudDtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/containers/saving/saving-form/saving-form.container.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;EACA,qBAAA;AACJ;;AAEA;EACI,uBAAA;AACJ;AACA,giBAAgiB","sourcesContent":[".omedom-page-container-content {\n    box-shadow: none !important;\n    padding: 0 !important;\n}\n\nomedom-stepper::ng-deep .stepper-header {\n    min-height: min-content;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
