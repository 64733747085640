// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  font-size: 20px;
  font-weight: 600;
}

.scenario-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.scenario-container > omedom-renovation-scenario {
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
}

.text-grey {
  color: grey;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInJlbm92YXRpb24tc2NlbmFyaW9zLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksZUFBQTtFQUNBLGdCQUFBO0FBQ0o7O0FBRUE7RUFDSSxhQUFBO0VBQ0Esc0JBQUE7RUFDQSx1QkFBQTtFQUNBLFNBQUE7QUFDSjtBQUNJO0VBQ0ksV0FBQTtFQUNBLHlCQUFBO0VBQ0EsbUJBQUE7QUFDUjs7QUFHQTtFQUNJLFdBQUE7QUFBSiIsImZpbGUiOiJyZW5vdmF0aW9uLXNjZW5hcmlvcy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi50aXRsZSB7XG4gICAgZm9udC1zaXplOiAyMHB4O1xuICAgIGZvbnQtd2VpZ2h0OiA2MDA7XG59XG5cbi5zY2VuYXJpby1jb250YWluZXIge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgICBnYXA6IDIwcHg7XG5cbiAgICA+IG9tZWRvbS1yZW5vdmF0aW9uLXNjZW5hcmlvIHtcbiAgICAgICAgd2lkdGg6IDEwMCU7XG4gICAgICAgIGJvcmRlcjogMXB4IHNvbGlkICNlMGUwZTA7XG4gICAgICAgIGJvcmRlci1yYWRpdXM6IDEwcHg7XG4gICAgfVxufVxuXG4udGV4dC1ncmV5IHtcbiAgICBjb2xvcjogZ3JleTtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/containers/valuation/renovation-scenarios/renovation-scenarios.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,gBAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,SAAA;AACJ;AACI;EACI,WAAA;EACA,yBAAA;EACA,mBAAA;AACR;;AAGA;EACI,WAAA;AAAJ;AACA,45BAA45B","sourcesContent":[".title {\n    font-size: 20px;\n    font-weight: 600;\n}\n\n.scenario-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    gap: 20px;\n\n    > omedom-renovation-scenario {\n        width: 100%;\n        border: 1px solid #e0e0e0;\n        border-radius: 10px;\n    }\n}\n\n.text-grey {\n    color: grey;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
