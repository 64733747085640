import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ChargeListComponent } from '../charge/containers/charge-list/charge-list.component';
import { ChargeEditPage } from '../charge/pages/charge-edit/charge-edit.page';
import { ChargeFormPage } from '../charge/pages/charge-form/charge-form.page';
import { ChargeInfoPage } from '../charge/pages/charge-info/charge-info.page';
import { IncomeListComponent } from '../income/containers/income-list/income-list.component';
import { IncomeEditPage } from '../income/pages/income-edit/income-edit.page';
import { IncomeFormPage } from '../income/pages/income-form/income-form.page';
import { IncomeInfoPage } from '../income/pages/income-info/income-info.page';
import { TreasuryHomePage } from './pages/treasury-home/treasury-home.page';

const routes: Routes = [
    {
        path: '',
        component: TreasuryHomePage,
    },
    {
        path: ':tab',
        component: TreasuryHomePage,
    },
    {
        path: 'charge',
        children: [
            {
                path: 'form',
                children: [
                    {
                        path: '',
                        component: ChargeFormPage,
                    },
                    {
                        path: ':uid',
                        component: ChargeFormPage,
                    },
                ],
            },
            {
                path: 'info',
                component: ChargeInfoPage,
            },
            {
                path: 'list/:startDate/:endDate',
                component: ChargeListComponent,
                data: { isChargeForAllProperty: true },
            },
            {
                path: 'edit/:uid/:type/:date',
                component: ChargeEditPage,
            },
        ],
    },
    {
        path: 'income',
        children: [
            {
                path: 'form',
                children: [
                    {
                        path: '',
                        component: IncomeFormPage,
                    },
                    {
                        path: ':uid',
                        component: IncomeFormPage,
                    },
                ],
            },
            {
                path: 'info',
                component: IncomeInfoPage,
            },
            {
                path: 'list/:startDate/:endDate',
                component: IncomeListComponent,
            },
            {
                path: 'edit/:uid/:type/:date',
                component: IncomeEditPage,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class TreasuryRoutingModule {}
