// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.charges-container {
  border-left: 2px solid #eeeeee;
}

.date-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 100%;
  align-items: center;
  width: 100%;
}
.date-bar-item {
  align-items: center;
  display: flex;
  flex: 1;
  gap: 5px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNoYXJnZS1saXN0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksOEJBQUE7QUFDSjs7QUFFQTtFQUNJLGFBQUE7RUFDQSxtQkFBQTtFQUNBLGVBQUE7RUFDQSxTQUFBO0VBQ0EsZUFBQTtFQUNBLG1CQUFBO0VBQ0EsV0FBQTtBQUNKO0FBQ0k7RUFDSSxtQkFBQTtFQUNBLGFBQUE7RUFDQSxPQUFBO0VBQ0EsUUFBQTtBQUNSIiwiZmlsZSI6ImNoYXJnZS1saXN0LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNoYXJnZXMtY29udGFpbmVyIHtcbiAgICBib3JkZXItbGVmdDogMnB4IHNvbGlkICNlZWVlZWU7XG59XG5cbi5kYXRlLWJhciB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogcm93O1xuICAgIGZsZXgtd3JhcDogd3JhcDtcbiAgICBnYXA6IDEwcHg7XG4gICAgbWF4LXdpZHRoOiAxMDAlO1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgd2lkdGg6IDEwMCU7XG5cbiAgICAmLWl0ZW0ge1xuICAgICAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgICAgICBkaXNwbGF5OiBmbGV4O1xuICAgICAgICBmbGV4OiAxO1xuICAgICAgICBnYXA6IDVweDtcbiAgICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./apps/app/src/app/charge/containers/charge-list/charge-list.component.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,eAAA;EACA,SAAA;EACA,eAAA;EACA,mBAAA;EACA,WAAA;AACJ;AACI;EACI,mBAAA;EACA,aAAA;EACA,OAAA;EACA,QAAA;AACR;AACA,o4BAAo4B","sourcesContent":[".charges-container {\n    border-left: 2px solid #eeeeee;\n}\n\n.date-bar {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    gap: 10px;\n    max-width: 100%;\n    align-items: center;\n    width: 100%;\n\n    &-item {\n        align-items: center;\n        display: flex;\n        flex: 1;\n        gap: 5px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
