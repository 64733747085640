import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-smart-popover',
    templateUrl: './smart-popover.component.html',
    styleUrls: ['./smart-popover.component.scss'],
})
export class SmartPopoverComponent {
    @Input() text: string;
}
