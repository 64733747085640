import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { Keyboard } from '@capacitor/keyboard';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['input.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputComponent),
            multi: true,
        },
    ],
})
export class InputComponent implements ControlValueAccessor, Validator {
    @Input() placeholder: string;

    @Input() label: string;

    @Input() name: string;

    @Input() icon: string;

    @Input() iconEnd: string;

    @Input() type:
        | 'date'
        | 'datetime-local'
        | 'email'
        | 'month'
        | 'number'
        | 'password'
        | 'search'
        | 'tel'
        | 'text'
        | 'time'
        | 'url'
        | 'bday'
        | 'week' = 'text';

    @Input() required: boolean;

    @Input() debounce = 0;

    @Input() min: number;

    @Input() max: number;

    @Input() maxLength: number;

    @Input() pattern;

    @Input() class: string;

    @Input() enableShowPassword: boolean;

    @Input() autocomplete: string;

    @Input() error: string;

    @Input() warning: string;

    @Input() boldLabel: boolean;

    @Input() inputmode: string;

    value: any;

    isDisabled: boolean;

    showPassword = false;

    /**
     * @description Event when user click on icon end of input
     * @author Jérémie Lopez
     * @memberof InputComponent
     */
    @Output()
    public iconEndClick = new EventEmitter<void>();

    private onChangeCallback: any;

    /**
     * @description Is focused
     * @author Jérémie Lopez
     * @memberof InputComponent
     */
    public isFocused = new BehaviorSubject<boolean>(false);

    constructor(private platform: Platform, private elementRef: ElementRef) {
        if (this.platform.is('capacitor')) {
            this.keyboardListener();
        }
    }

    validate(): null {
        return undefined;
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(): void {}

    writeValue(newValue: any): void {
        if (newValue !== this.value) {
            this.value = newValue;
        }
    }

    setDisabledState(isDisabled: boolean): void {
        // Ne fonctionne que si c'est du template driven
        this.isDisabled = isDisabled;
    }

    valueChange(value: any) {
        this.writeValue(value);
        this.onChangeCallback(value);
    }

    private async keyboardListener(): Promise<void> {
        Keyboard.addListener('keyboardWillShow', async () => {
            this.elementRef.nativeElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        });
    }
}
