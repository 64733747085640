import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-create-first-item-button',
    templateUrl: './create-first-item-button.component.html',
    styleUrls: ['./create-first-item-button.component.scss'],
})
export class CreateFirstItemButtonComponent {
    @Input() buttonIcon = '';

    @Input() message = '';

    @Output() buttonClicked = new EventEmitter();
}
