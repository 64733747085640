import { DashboardType, OmedomColorsEnum, PropertyType, SocietyEntity } from '@omedom/data';
import { Component, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { UserEntity, ChargeEntity, IncomeEntity, PropertyEntity, Story } from '@omedom/data';
import { StoryDisplayPage } from '../../../story/pages/story-display/story-display.page';
import { ModalController } from '@ionic/angular';
import { OmedomDateType } from '../../../component/date';
import * as lodash from 'lodash';
import { OmedomMovement } from '@omedom/utils';
import { AnalyticsService } from '@omedom/services';

@Component({
    selector: 'app-widget-movements-summary',
    templateUrl: './widget-movements-summary.component.html',
    styleUrls: ['./widget-movements-summary.component.scss'],
})
export class WidgetMovementsSummaryComponent implements OnChanges {
    @Input() incomes: IncomeEntity[] = [];

    @Input() charges: ChargeEntity[] = [];

    @Input() user: UserEntity;

    @Input() properties: PropertyEntity[] = [];

    @Input() societies: SocietyEntity[] = [];

    @Input() dashboardType: DashboardType;

    @Input() deactivateNoSmart: boolean = false;

    @Output() update = new EventEmitter();

    storiesByDay: { date: Date; stories: Story[] }[] = [];
    storiesBeforeToday: Story[] = [];
    storiesAfterToday: Story[] = [];

    currentDate = new Date().toUTC();

    isLoaded: boolean;

    omedomDateType = OmedomDateType;

    /**
     * @description
     * @author ANDRE Felix
     * @memberof WidgetMovementsSummaryComponent
     */
    hasUnpayedStory = false;

    /**
     * @description Message to display in popver unpayed charge/income
     * @author ANDRE Felix
     * @memberof WidgetMovementsSummaryComponent
     */
    popoverUnpayed = {
        message: 'Certaines charges et/ou revenus apparaissent comme impayés ce mois-ci.',
        iconColor: 'red',
        icon: 'uil uil-exclamation-triangle',
    };

    constructor(
        private modalController: ModalController,
        private analyticsService: AnalyticsService,
    ) {}

    dateChange(date: Date) {
        this.currentDate = date;
        this.updateData().then();
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.charges ||
            changes.incomes ||
            changes.properties ||
            changes.societies
            // && !this.deactivateNoSmart
        ) {
            this.updateData();
        }
    }
    async storyClicked(story: Story): Promise<void> {
        if (this.deactivateNoSmart) {
            return;
        }
        this.analyticsService.logEvent('Story clicked home page in "synthèses des mouvements" ');
        const stories = [...this.storiesBeforeToday, ...this.storiesAfterToday];
        const selectedIndex = stories.findIndex((h) => h.uid === story.uid);
        const modal = await this.modalController.create({
            component: StoryDisplayPage,
            breakpoints: [0, 1],
            initialBreakpoint: 1,
            canDismiss: true,
            componentProps: {
                selectedIndex: selectedIndex,
                stories: [...this.storiesBeforeToday, ...this.storiesAfterToday],
                canNavigate: true,
            },
        });

        await modal.present();

        // modal.onDidDismiss().then(() => this.updateData().then());
        modal.onDidDismiss().then(() => this.update.emit());
    }

    private async updateData(): Promise<void> {
        const charges = lodash.cloneDeep(this.charges);
        const incomes = lodash.cloneDeep(this.incomes);

        const stories = OmedomMovement.getAllStoriesByPeriod(
            charges,
            incomes,
            this.properties,
            this.societies,
            this.currentDate.getUTCFirstDayOfMonth(),
            this.currentDate.getUTCLastDayOfMonth(),
            false,
            true,
            false,
        );

        this.storiesBeforeToday = stories.filter((h) => h.date < this.currentDate);
        this.storiesAfterToday = stories.filter((h) => h.date >= this.currentDate);
        this.hasUnpayedStory = OmedomMovement.hasSomeStoriesNotPayed(this.storiesBeforeToday);
        this.isLoaded = true;
    }

    protected isTreasurySociety(story: Story): boolean {
        let treasury: IncomeEntity | ChargeEntity;
        if (story.isCharge) {
            treasury = this.charges.find((h) => h.uid === story.uid);
        } else {
            treasury = this.incomes.find((h) => h.uid === story.uid);
        }

        return !!treasury?.societyUID;
    }

    protected isTreasuryBuilding(story: Story): boolean {
        let treasury: IncomeEntity | ChargeEntity;
        if (story.isCharge) {
            treasury = this.charges.find((h) => h.uid === story.uid);
        } else {
            treasury = this.incomes.find((h) => h.uid === story.uid);
        }

        // find the property
        const property = this.properties.find((h) => h.uid === treasury?.propertyUID);

        return property?.type === PropertyType.immeuble;
    }
}
