import { Component, Input } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
    @Input() avatar: string;

    @Input() title: string;

    @Input() image: string;

    constructor(private modalController: ModalController, private navController: NavController) {}

    async dismiss(): Promise<void> {
        await this.modalController.dismiss();
    }

    back() {
        this.dismiss();
        this.navController.back();
    }
}
