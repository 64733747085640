import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-validation-modal',
    templateUrl: './validation-modal.component.html',
    styleUrls: ['./validation-modal.component.scss'],
})
export class ValidationModalComponent {
    @Input()
    public title!: string;

    @Input()
    public message!: string;

    @Input()
    public validateButtonMessage!: string;

    @Input()
    public cancelButtonMessage!: string;

    @Input()
    inconInfo = '';

    @Input()
    avatar: string;

    constructor(private readonly modalController: ModalController) {}

    public async dismissModal(confirmation: boolean): Promise<void> {
        await this.modalController.dismiss(confirmation);
    }
}
