import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { OmedomSubTab } from './omedom-sub-tab';

@Component({
    selector: 'app-sub-tab',
    templateUrl: './sub-tab.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SubTabComponent {
    @Input() subTabs: OmedomSubTab[] = [];

    @Input() selectedSubTab: OmedomSubTab;

    @Output() selectedSubTabChange = new EventEmitter<OmedomSubTab>();

    subTabClicked(tab: OmedomSubTab): void {
        if (tab.disabled) {
            return;
        }

        this.selectedSubTab = tab;
        this.selectedSubTabChange.next(tab);
    }
}
