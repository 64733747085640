// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.omedom-page-container-content {
  box-shadow: none !important;
  padding: 0 !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNhdmluZy1mb3JtLmNvbnRhaW5lci5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksMkJBQUE7RUFDQSxxQkFBQTtBQUNKIiwiZmlsZSI6InNhdmluZy1mb3JtLmNvbnRhaW5lci5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLm9tZWRvbS1wYWdlLWNvbnRhaW5lci1jb250ZW50IHtcbiAgICBib3gtc2hhZG93OiBub25lICFpbXBvcnRhbnQ7XG4gICAgcGFkZGluZzogMCAhaW1wb3J0YW50O1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/containers/saving/saving-form/saving-form.container.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;EACA,qBAAA;AACJ;AACA,wZAAwZ","sourcesContent":[".omedom-page-container-content {\n    box-shadow: none !important;\n    padding: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
