import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import {
    SelectOption,
    SocietyEntity,
    SocietyMember,
    SocietyRoleMember,
    SocietyStatusMember,
    UserEntity,
} from '@omedom/data';
import { ShareService, SmartService, SocietyService } from '@omedom/services';
import { OmedomRegex, OmedomSociety } from '@omedom/utils';
import { BehaviorSubject, of, Subscription } from 'rxjs';

import { OmedomRadioOption } from '../../../component/radio';

@Component({
    selector: 'app-society-member-form',
    templateUrl: './society-member-form.component.html',
    styleUrls: ['./society-member-form.component.scss'],
})
export class SocietyMemberFormComponent implements OnInit, OnDestroy {
    /**
     * @description Society Entity data
     * @author Jérémie Lopez
     * @type {SocietyEntity}
     * @memberof SocietyMemberFormComponent
     */
    @Input() society: SocietyEntity;

    /**
     * @description Society Member data
     * @author Jérémie Lopez
     * @type {SocietyMember}
     * @memberof SocietyMemberFormComponent
     */
    @Input() member: SocietyMember;

    /**
     * @description Index of the member in the list
     * @author Jérémie Lopez
     * @type {number}
     * @memberof SocietyMemberFormComponent
     */
    @Input() sharingIndex: number;

    /**
     * @description if the user is in mode Smart or not
     * @author Hanane Djeddal
     * @type {boolean}
     * @memberof SocietyMemberFormComponent
     */
    public hasSmart = false;
    /**
     * @description Form Group member infos
     * @author Jérémie Lopez
     * @type {FormArray}
     * @memberof SocietyMemberFormComponent
     */
    public memberForm: UntypedFormGroup;

    /**
     * @description Options to radio buttons
     * @author Jérémie Lopez
     * @type {OmedomRadioOption[]}
     * @memberof SocietyMemberFormComponent
     */

    public emailRegex: string = OmedomRegex.emailRegex;
    public phoneRegex = OmedomRegex.phoneRegex;

    public statusOptions: OmedomRadioOption[] = [
        new OmedomRadioOption({
            id: SocietyStatusMember.associated,
            label: SocietyStatusMember.associated,
        }),
        new OmedomRadioOption({
            id: SocietyStatusMember.manager,
            label: SocietyStatusMember.manager,
        }),
    ];

    /**
     * @description Placeholder of select component
     * @author Jérémie Lopez
     * @memberof SocietyMemberFormComponent
     */
    public rolePlaceholder = {
        id: null,
        label: 'Rôle',
    } as SelectOption;

    /**
     * @description List type of status in the select component
     * @author Jérémie Lopez
     * @memberof SocietyMemberFormComponent
     */
    public roleOptions$ = of([
        {
            id: SocietyRoleMember.reader,
            label: SocietyRoleMember.reader,
        } as SelectOption,
        {
            id: SocietyRoleMember.editor,
            label: SocietyRoleMember.editor,
        } as SelectOption,
        {
            id: SocietyRoleMember.admin,
            label: SocietyRoleMember.admin,
        } as SelectOption,
    ]);

    /**
     * @description If true, the app is pending
     * @author Jérémie Lopez
     * @memberof SocietyMemberFormComponent
     */
    public pending$ = new BehaviorSubject<boolean>(false);

    private subscriptions: Subscription[] = [];

    private user: UserEntity;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private societyService: SocietyService,
        private modalController: ModalController,
        private toastController: ToastController,
        private smartService: SmartService,
        private shareService: ShareService
    ) {}

    async ngOnInit(): Promise<void> {
        this.initForm(this.member);

        const smart$ = this.smartService.hasSmart$.subscribe((hasSmart) => {
            this.hasSmart = hasSmart;
        });

        this.subscriptions.push(smart$);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    private initForm(member?: SocietyMember): void {
        this.memberForm = this.formBuilder.group({
            name: [member?.name ?? '', Validators.required],
            firstname: [member?.firstname ?? '', Validators.required],
            email: [
                member?.email ?? '',
                [Validators.required, Validators.email, this.mailValidator()],
            ],
            tel: [member?.tel ?? '', []],
            status: [
                {
                    value: member?.status ?? SocietyStatusMember.associated,
                    disabled: this.isStatusCanBeEdited(),
                },
                Validators.required,
            ],
            isCreator: [member?.isCreator ?? false],
        });
    }

    private isStatusCanBeEdited() {
        if (this.member && this.member.status === SocietyStatusMember.manager) {
            // if we update a member which is manager
            return false;
        }
        // We can't modify status 'associate' if manager present
        return OmedomSociety.isSocietyManagerExist(this.society);
    }

    private mailValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const email = control.value;
            if (!email || this.member) {
                return null;
            }
            const isEmailAlreadyUse = this.shareService.isEmailAlreadyInMembersOrSharing(
                email,
                this.society.sharing,
                this.society.members
            );
            if (isEmailAlreadyUse) {
                this.displayErrorEmailToast();
                return { existingShare: true };
            }
            return null;
        };
    }

    private async displayErrorEmailToast() {
        const toast = await this.toastController.create({
            position: 'top',
            color: 'danger',
            duration: 5000,
            message: 'Cet email est déjà utilisé pour un partage ou pour un membre',
        });

        await toast.present();
    }

    public async submit(): Promise<void> {
        this.pending$.next(true);
        const member = this.memberForm.getRawValue() as SocietyMember;
        await this.saveAndDisplayToast(member);

        this.pending$.next(false);

        await this.modalController.dismiss();
    }

    private async saveAndDisplayToast(member: SocietyMember): Promise<void> {
        let toastMessage = 'Vous avez ajouté un membre à cette société.';

        if (this.member && this.sharingIndex !== undefined) {
            toastMessage = 'Vous avez mis à jour un membre.';
            await this.shareService.updateSocietyMember(this.society, member, this.sharingIndex);
        } else {
            await this.shareService.addSocietyMember(this.society, member, this.sharingIndex);
        }
        const toast = await this.toastController.create({
            position: 'top',
            color: 'primary',
            message: toastMessage,
            duration: 4000,
        });
        await toast.present();
    }

    public async close(): Promise<void> {
        this.modalController.dismiss();
    }
}
