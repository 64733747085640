import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { UserService } from '@omedom/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ProviderGuard {
    constructor(private userService: UserService, private router: Router) {}

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return (
            this.userService.user$.pipe(
                map((user) => {
                    if (!user) {
                        this.router.navigate(['/user/auth']);
                        return false;
                    }
                    if (!user.name || !user?.tel || !user.firstname) {
                        this.router.navigate(['/tunnel/confirm-infos']);
                        return false;
                    } else {
                        return true;
                    }
                })
            ) ?? false
        );
    }
}
