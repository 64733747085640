import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-filter-property-button',
    templateUrl: './filter-property-button.component.html',
    styleUrls: ['./filter-property-button.component.scss'],
})
export class FilterPropertyButtonComponent {
    @Input() displayProperty = true;

    @Input() displaySociety = true;

    @Output() filterPropertyClicked = new EventEmitter();

    @Output() filterSocietyClicked = new EventEmitter();
}
