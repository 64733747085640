import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetTreasuryComponent } from './containers/widget-treasury/widget-treasury.component';
import { WidgetNextMovementsComponent } from './containers/widget-next-movements/widget-next-movements.component';
import { OmedomComponentModule } from '../component/omedom-component.module';
import { NgChartsModule } from 'ng2-charts';
import { RouterModule } from '@angular/router';
import { WidgetTotalTreasuryAmountComponent } from './containers/widget-total-treasury-amount/widget-total-treasury-amount.component';
import { WidgetMovementsSummaryComponent } from './containers/widget-movements-summary/widget-movements-summary.component';
import { WidgetRentabilityComponent } from './containers/widget-rentability/widget-rentability.component';
import { UiModule } from '@omedom/ui';
import { WidgetRentalEventsComponent } from './containers/widget-rental-events/widget-rental-events.component';

@NgModule({
    declarations: [
        WidgetMovementsSummaryComponent,
        WidgetNextMovementsComponent,
        WidgetRentalEventsComponent,
        WidgetTreasuryComponent,
        WidgetTotalTreasuryAmountComponent,
        WidgetRentabilityComponent,
    ],
    imports: [CommonModule, UiModule, OmedomComponentModule, NgChartsModule, RouterModule],
    exports: [
        WidgetMovementsSummaryComponent,
        WidgetNextMovementsComponent,
        WidgetRentabilityComponent,
        WidgetRentalEventsComponent,
        WidgetTotalTreasuryAmountComponent,
        WidgetTreasuryComponent,
    ],
})
export class WidgetModule { }
